<template>
  <div>
    <b-card class="quoterCard pl-4">
      <ul class="pl-0">
        <li>La sección Simulador, te permite obtener un archivo con una comparación de tarifas que enviaremos al correo del usuario registrado.</li>
      </ul>
      <div v-for="(container, index) of containers" class="container-quoter-rows" :key="`container${index}`">
        <div class="container-quoter-number">{{ index + 1 }}</div>
        <form-render
          :ref="`formRenderSimulator${index}`"
          :key="keyForm[container.keyFormRender]"
          class="fieldQuoter"
          :fields="container.field"
          :form.sync="form"
        >
          <template #couriersTags>
            <p>{{ keyForm[container.keyFormRender] }}</p>
            <b-badge class="badge__cnt" v-for="(data, index) in valueCourierTags" :key="`${index}_valueCourierTags`">
              {{data.text}}
              <b-button variant="outline" class="border-0" size="sm" @click="removeCouriersTags(index)">
                <feather-icon icon="XIcon" size="20" class="warning color_icon" />
              </b-button>
            </b-badge>
          </template>
          <template #classificationTags>
            <p>{{ keyForm[container.keyFormRender] }}</p>
            <b-badge class="badge__cnt" v-for="(data, index) in valueClassificationTags" :key="`${index}_valueClassificationTags`">
              {{data.text}}
              <b-button variant="outline" class="border-0" size="sm" @click="removeClassificationTags(index)">
                <feather-icon icon="XIcon" size="20" class="warning color_icon" />
              </b-button>
            </b-badge>
          </template>
          <template #labelBillingField>
            <label>Carga de archivo</label>
            <span class="text-danger"> *</span>
            <b-button size="sm" v-if="form.country && url" :href="url" variant="link" target="_blank">( Descargar Formato ) </b-button>
          </template>
          <template #simulatorMultiCourier>
            <div class="separation" v-if="valueCourierTags.length >= 2 || form.checkboxAllCouriers"></div>
            <b-form-checkbox
              id="simulatorMultiCourierTest"
              v-if="valueCourierTags.length >= 2 || form.checkboxAllCouriers"
              class="custom-switch-success"
              :checked="form.simulatorMultiCourier"
              switch
              v-model="form.simulatorMultiCourier"
              @change="visualizationFieldNumberCarriers('simulatorMultiCourier', form.simulatorMultiCourier)"
              validation="required"
            >
              {{$t('Agregar simulación de operación multicourier')}}
            </b-form-checkbox>
          </template>
        </form-render>
      </div>
      <b-button variant="warning" class="quoterButton" @click="validateForm" :disabled="loading.simulatorFile">
        <span v-if="loading.simulatorFile" class="mt-0 pl-2 pr-2"><b-spinner small /> </span>
        <span v-if="!loading.simulatorFile" class="mb-1">Confirmar</span>
      </b-button>
      <internal-tooltip
        v-if="valueCourierTags.length >= 2 || form.checkboxAllCouriers"
        tooltipId="simulator-tooltip"
        :expirationDate="expirationDate"
        target="simulatorMultiCourierTest"
        text="Ahora obtén las combinaciones multicourier más convenientes para tu cliente, incluyendo un análisis de ahorro, cobertura y valor total por operación."
        ref="tooltipRef"
        placement="bottomright"
        classTooltip="mt-2"
      />
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  data () {
    return {
      baseService: new BaseServices(this),
      containers: [{field: []}],
      form: {},
      keyForm: { 3: 0 },
      fieldObj: {
        stepOne: [],
        stepTwo: [],
        stepThree: [],
        stepFour:[]
      },
      loading: {
        simulatorFile: false
      },
      valueCourierTags: [],
      valueClassificationTags: [],
      url: '',
      toastRef: null,
      optionsRadioType: [
        { id: 1, text:  this.$t('Cargar datos de operación') },
        { id: 2, text: this.$t('Seleccionar origen') }
      ],
      currencySimbol: {
        CL: '$',
        CO: '$',
        PE: 'S/',
        MX: '$'
      },
      declaredValue: {
        fieldType: 'FieldInput',
        name: 'declaredValue',
        label: 'Valor Promedio',
        type: 'number',
        validation: 'required',
        containerClass: 'col-sm-12 col-md-3 col-lg-3 mb-1',
        dependency: 'country',
        clearable: true,
        prepend: {}
      },
      expirationDate: new Date(new Date().setDate(new Date().getDate() + 15)).toISOString()
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesQuoter',
      couriers: 'getCarriersOriginsModal',
      places: 'getPlacesRatesQuoter',
      servicesTypes: 'getServiceTypesQuoter'
    })
  },
  watch: {
    countries() {
      this.setCountries()
    },
    couriers() {
      this.setCouriers()
    },
    servicesTypes() {
      this.setServicesTypes()
    },
    valueCourierTags() {
      if (this.valueCourierTags.length || this.form.checkboxAllCouriers) {
        this.containers[1].field[0].validation = ''
      } else {
        this.containers[1].field[0].validation = 'required'
      }
      this.visualizationFieldNumberCarriers()
    },
    valueClassificationTags() {
      if (this.valueClassificationTags.length) {
        this.containers[2].field[0].validation = ''
      } else {
        this.containers[2].field[0].validation = 'required'
      }
    },

    generalLoading: {
      handler () {
        this.loading.countries = !!this.generalLoading.getCountriesQuoter
        this.loading.simulatorFile = !!this.generalLoading.getSimulatorFile
      }
    }
  },
  mounted () {
    this.setInitialData()
    this.getCountries()
  },
  methods: {
    setInitialData () {
      this.fieldObj.stepOne = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', disabled: false, label: 'País', validation: 'required', containerClass: 'col-sm-12 col-md-8 col-lg-3 fieldSelect', change: this.changeCountry, addFlags: true, sort: true }
      ]
      this.fieldObj.stepTwo = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'courier', label: 'Courier', containerClass: 'col-sm-12 col-md-8 col-lg-3 required-not-background', clearable: true, dependency: 'country', change: this.changeCouriers, sort: true},
        { name: 'couriersTags', useSlot: true, containerClass: 'col-12' },
        { fieldType: 'FieldCheckbox', name: 'checkboxAllCouriers', containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', align: 'h', options: [{id: 1, text: 'Simular con todos los couriers disponibles'}], change: this.changeCheckboxAllCouriers, dependency: 'country'},
        { name: 'simulatorMultiCourier', containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', useSlot: true }
      ]
      this.fieldObj.stepThree = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'classification', label: 'Clasificación servicio', validation: 'required', disabled: false, containerClass: 'col-sm-12 col-md-8 col-lg-3', clearAfterSelect: true, dependency: 'country', placeholder: 'Seleccione hasta 5 categorías', change: this.changeClassification, sort: true},
        { name: 'classificationTags', useSlot: true, containerClass: 'col-12' }
      ]
      this.fieldObj.stepFour = [
        { fieldType: 'FieldRadio', name: 'radioType',  align: 'h', options: this.optionsRadioType, change: this.changeRadioType, containerClass: 'col-12 mt-1 mb-1', dependency: 'country', validation: 'required'}
      ]
      this.containers = [
        {field: this.fieldObj.stepOne, keyFormRender: 0},
        {field: this.fieldObj.stepTwo, keyFormRender: 1},
        {field: this.fieldObj.stepThree, keyFormRender: 2},
        {field: this.fieldObj.stepFour, keyFormRender: 3}
      ]
    },
    getCountries() {
      this.$store.dispatch('fetchService', { name: 'getCountriesQuoter' })
    },
    getCouriersAndServicesTypes () {
      const params = {
        country_id: this.form.country.id
      }
      this.$store.dispatch('fetchService', { name: 'getCarriersOriginsModal', params })
      this.$store.dispatch('fetchService', { name: 'getServiceTypesQuoter', params })
    },
    getIndex(val, array, key) {
      return array.findIndex((el) => el[key] === val)
    },
    async setPlaces(value) {
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {
        search: value
      }
      return await this.baseService
        .callService('getPlacesRatesQuoter', queryParams, params)
        .then((resp) => {
          return resp.data.places.map((el) => ({
            ...el,
            text: el.full_name,
            valid: true
          }))
        })
        .catch((err) => {
          return err
        })
    },
    setCountries() {
      this.fieldObj.stepOne[0].options = this.countries.rows
      this.setSkeletonField('stepOne', false)
    },
    setCouriers() {
      this.fieldObj.stepTwo[0].options = this.couriers.rows
      this.setSkeletonField('stepTwo', false)
    },
    setServicesTypes() {
      const options = this.servicesTypes.rows.map(el => ({...el, text: el.name}))
      this.fieldObj.stepThree[0].options = options.filter(el => el.type !== 'express' && el.type !== 'international')
      this.setSkeletonField('stepThree', false)
    },
    setSkeletonField (name, bool) {
      this.fieldObj[name][0].useSlot = bool
      this.fieldObj[name][0].useSkeleton = bool
      this.fieldObj[name][0].useLabel = bool
    },
    resetField(name) {
      setTimeout(() => {
        this.setSkeletonField(name, false)
      }, 100)
    },
    changeCountry(name, value) {
      this.containers[1].field[2].value = true
      this.setSkeletonField('stepTwo', true)
      this.setSkeletonField('stepThree', true)
      this.clearForm()
      const indexDeclaredValue = this.fieldObj.stepFour.findIndex(el => el.name === 'declaredValue')
      if (this.valueCourierTags.length) this.valueCourierTags = []
      if (this.valueClassificationTags.length) this.valueClassificationTags = []
      if (value) {
        if (value.code === 'CO' && this.form.radioType?.id === 2 && indexDeclaredValue === -1) {
          this.declaredValue.prepend.text = this.currencySimbol[value.code]
          this.fieldObj.stepFour.push(this.declaredValue)
        } else if (indexDeclaredValue !== -1) {
          this.fieldObj.stepFour.splice(indexDeclaredValue, 1)
        }
        this.getCouriersAndServicesTypes()
        switch (value.code.toLowerCase()) {
        case 'cl':
          this.url = 'https://storage.cloud.google.com/formato-archivos-subida-akatsuki/simulator/t8-1486/simulator-formato-subida-CL.xlsx'
          break
        case 'co':
          this.url = 'https://storage.cloud.google.com/formato-archivos-subida-akatsuki/simulator/t8-1486/simulator-formato-subida-CO.xlsx'
          break
        case 'mx':
          this.url = 'https://storage.cloud.google.com/formato-archivos-subida-akatsuki/simulator/t8-1486/simulator-formato-subida-MX.xlsx'
          break
        case 'pe':
          this.url = 'https://storage.cloud.google.com/formato-archivos-subida-akatsuki/simulator/t8-1486/simulator-formato-subida-PE.xlsx'
          break
        }
      } else {
        this.form = {}
        Object.keys(this.form).forEach(el => { if (el !== 'country') this.form[el] = undefined })
        this.fieldObj.stepTwo[0].containerClass = 'col-sm-12 col-md-8 col-lg-4'
        this.fieldObj.stepThree[0].containerClass = 'col-sm-12 col-md-8 col-lg-4'
        this.refreshElements(false)
      }
    },
    changeCouriers(name, value) {
      this.setSkeletonField('stepTwo', true)
      if (this.valueCourierTags.some(ele => ele?.id === value?.id) || !value) {
        this.form[name] = undefined
        this.resetField('stepTwo')
        return
      }
      this.valueCourierTags.push(value)
      this.form[name] = undefined
      this.resetField('stepTwo')
    },
    changeClassification(name, value) {
      this.setSkeletonField('stepThree', true)
      if (!(this.valueClassificationTags.some(ele => ele?.id === value?.id)) || !value) {
        this.valueClassificationTags.push(value)
      }
      this.form[name] = undefined
      this.resetField('stepThree')
      this.checkValidation('classification')
    },
    visualizationFieldNumberCarriers() {
      const options = []
      let number = null
      if (this.$refs.tooltipRef) this.$refs.tooltipRef.visible = false
      delete this.form.max_combinations
      if (this.getIndex('max_combinations', this.fieldObj.stepTwo, 'name') !== -1) this.fieldObj.stepTwo.splice(this.fieldObj.stepTwo.findIndex(el => el.name === 'max_combinations'), 1)
      if (this.valueCourierTags.length > 1 && this.valueCourierTags.length <= 5) number = this.valueCourierTags.length
      if (this.valueCourierTags.length > 5 || this.form.checkboxAllCouriers) number = 5
      for (let i = 2; i < number + 1; i++) {
        options.push({id:i, text: i})
      }
      setTimeout(() => {
        if (number && this.form.simulatorMultiCourier && this.getIndex('max_combinations', this.fieldObj.stepTwo, 'name') === -1) {
          this.fieldObj.stepTwo.push({
            fieldType: 'FieldRadio', name: 'max_combinations', containerClass: 'col-sm-12 col-md-12 mb-1', align: 'h', options, label: 'Máximo número de couriers por combinación', validation: 'required'
          })
        }
      }, 50)
    },
    changeCheckboxAllCouriers(name, value) {
      if (value) {
        this.valueCourierTags = []
        this.containers[1].field[0].validation = ''
        this.setFieldsProperties(this.containers[1].field, 'courier', 'disabled', true)
      } else {
        this.setFieldsProperties(this.containers[1].field, 'courier', 'disabled', false)
        this.containers[1].field[0].validation = 'required'
      }
      this.visualizationFieldNumberCarriers()
    },
    changeRadioType(name, value) {
      const indexLabelBillingField = this.fieldObj.stepFour.findIndex(el => el.name === 'labelBillingField')
      const indexPlaces = this.fieldObj.stepFour.findIndex(el => el.name === 'places')
      const indexDeclaredValue = this.fieldObj.stepFour.findIndex(el => el.name === 'declaredValue')
      delete this.form.billingFile
      if (value.id === 1 && indexLabelBillingField === -1) {
        this.deleteFieldStepFour('places')
        this.deleteFieldStepFour('weightValue')
        this.deleteFieldStepFour('declaredValue')
        this.fieldObj.stepFour.push(
          { name: 'labelBillingField', useSlot: true, containerClass: 'col-12' },
          { fieldType: 'FieldFileDropArea', name: 'billingFile', validation: 'required', containerClass: 'col-sm-12 col-md-8 col-lg-4 mb-1', accept: '.xlsx, .xls', classField:'hide-button-browse', labelFormFile: 'Seleccionar archivo', styleSmall: true}
        )
      } else if (value.id === 2 && indexPlaces === -1) {
        this.deleteFieldStepFour('labelBillingField')
        this.deleteFieldStepFour('billingFile')
        this.fieldObj.stepFour.push(
          {
            fieldType: 'FieldSelect',
            useLabel: true,
            name: 'places',
            label: 'Origen',
            validation: 'required',
            containerClass: 'col-12 col-md-4 col-lg-4 mb-1',
            dependency: 'country',
            clearable: true,
            searchOnType: {
              fx: this.setPlaces,
              nChars: 3,
              debounce: 600,
              allowSearchAsValue: false,
              persistSearch: true
            }
          },
          {
            fieldType: 'FieldInput',
            name: 'weightValue',
            label: 'Peso Promedio.',
            type: 'number',
            validation: 'required',
            containerClass: 'col-sm-12 col-md-3 col-lg-3 mb-1',
            append: {text: 'Kg', color: '#BBBBBB'},
            dependency: 'country',
            clearable: true
          }
        )
        if (this.form.country.code.toLowerCase() === 'co' && indexDeclaredValue === -1) {
          this.declaredValue.prepend.text = this.currencySimbol[this.form.country.code]
          this.fieldObj.stepFour.push(this.declaredValue)
        }
      }
    },
    checkValidation (name) {
      // Eliminar o agregar el valor requerido en el input de destino para que al momento de enviar el formulario no interrumpa el proceso debido a como se muestra en la UI los tags de destinos, llos valores se toman del fieldSelect pero se agregan a un array "valueTags"
      if (this.valueClassificationTags.length >= 1 && this.containers[2].field[0].validation === 'required') this.setFieldsProperties(this.containers[2].field, name, 'validation', '')
      if (this.valueClassificationTags.length === 0 && this.containers[2].field[0].validation === '') this.setFieldsProperties(this.containers[2].field, name, 'validation', 'required')
      //No permite agregar mas de 5 categorías
      if (this.valueClassificationTags.length >= 5) {
        this.setFieldsProperties(this.containers[2].field, name, 'disabled', true)
        this.setFieldsProperties(this.containers[2].field, name, 'placeholder', 'Ingresaste el máximo de 5 categorías')
        this.setFieldsProperties(this.containers[2].field, name, 'containerClass', 'col-sm-12 col-md-8 col-lg-4')
      } else if (this.containers[2].field[0].disabled) {
        this.setFieldsProperties(this.containers[2].field, name, 'disabled', false)
        this.setFieldsProperties(this.containers[2].field, name, 'containerClass', 'col-sm-12 col-md-8 col-lg-4 fieldSelect')
        this.setFieldsProperties(this.containers[2].field, name, 'placeholder', 'Seleccione hasta 5 categorías')
      }
    },
    deleteFieldStepFour(name) {
      const index = this.fieldObj.stepFour.findIndex(el => el.name === name)
      if (index !== -1) this.fieldObj.stepFour.splice(index, 1)
    },
    async validateForm () {
      const value1 = await this.$refs.formRenderSimulator0[0].onlyCheckForm()
      const value2 = await this.$refs.formRenderSimulator1[0].onlyCheckForm()
      const value3 = await this.$refs.formRenderSimulator2[0].onlyCheckForm()
      const value4 = await this.$refs.formRenderSimulator3[0].onlyCheckForm()
      const allValues = [value1 && value2 && value3 && value4]
      const isCheck = allValues.every(el => el === true)
      if (isCheck) this.sendForm()
    },
    refreshElements(value, omit = null) {
      setTimeout(() => {
        Object.keys(this.fieldObj).map(key => {
          if (key !== omit && key !== 'stepOne' && key !== 'stepFour') this.setSkeletonField(key, value)
        })
      }, 100)
    },
    clearForm () {
      Object.keys(this.form).forEach(el => {
        if (el !== 'country' && el !== 'radioType') delete this.form[el]
        const indexPlaces = this.fieldObj.stepFour.findIndex(el => el.name === 'places')
        if (indexPlaces !== -1) this.fieldObj.stepFour[indexPlaces].options = []
      })
      this.changeCheckboxAllCouriers()
    },
    sendForm() {
      const formData = new FormData()
      if (this.form.billingFile) formData.append('file', this.form.billingFile)
      if (this.form.country.code) formData.append('country_code', this.form.country.code)
      if (this.form.declaredValue) formData.append('declared_value', this.form.declaredValue)
      if (this.form.places) formData.append(`${this.form.radioType.id === 1 ? 'place_id' : 'from_place'}`, this.form.places.id)
      if (this.form.weightValue) formData.append('average_weight', this.form.weightValue)
      if (this.form.max_combinations) formData.append('max_combinations', this.form.max_combinations.id)
      if (!this.form.checkboxAllCouriers) {
        this.valueCourierTags.forEach(el => {
          formData.append('carrier_ids[]', el.id)
        })
      }
      this.valueClassificationTags.forEach(el => {
        formData.append('service_type_ids[]', el.id)
      })
      this.loading.simulatorFile = true
      let name = ''
      if (this.form.radioType.id === 1) name = 'getSimulatorFile'
      else if (this.form.radioType.id === 2) name = 'getSimulatorByParams'
      this.baseService.callUploadFileFormData(name, formData, {})
        .then(() => {
          this.$newSuccess('¡Envío de Simulación Ok!', 'Revise dentro de unos minutos la bandeja del correo asociado a su usuario Envíame.')
        })
        .catch((err) => {
          this.$alert(err.message, { code: err.code })
        })
        .finally(() => {
          this.loading.simulatorFile = false
        })
    },
    setFieldsProperties (fields, name, propertyField, value) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index][propertyField] = value
      }
    },
    removeCouriersTags (index) {
      this.valueCourierTags.splice(index, 1)
    },
    removeClassificationTags (index) {
      this.valueClassificationTags.splice(index, 1)
      this.checkValidation('classification')
    }
  }
}
</script>

<style lang="scss">
  .badge__cnt{
    margin: 5px;
    background: #58bbe4;
    padding: 2px 5px;
  }
  .color_icon{ //color para el icono X de los tags
    stroke: white;
  }
  .required-not-background .invalid-feedback {
    background: #F0F7FA !important;
    padding-top: 0.25rem;
    margin-top: 0;
  }
  .separation {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 1rem;
    margin-right: 2rem;
  }
</style>
