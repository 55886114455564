<template>
  <div>
    <filter-swapper id="TariffsMultipleCouriers" :buttons="buttons" :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render
          :key="keyFormRender"
          :fields="fields"
          :form.sync="form"
          @send="buttonFind"
          class="px-0"
          containerButtonsClass="col-sm-12 col-md-12 col-lg-3 mt-2 mb-4 buttons-rates-costs"
        >
          <template #buttons>
            <b-button 
              :variant="(form.carriers && form.carriers.length > 1 || form.categoryService && form.categoryService.length > 1) ? 'success' : 'warning'"
              :disabled="buttonSend.disabled || loading.prices || loading.rates || loading.categoryService || loading.services || loading.getMultipleRates"
              @click="(form.carriers && form.carriers.length > 1 || form.categoryService && form.categoryService.length > 1) ? openModalPricesOrCosts() : buttonFind()"
              v-b-tooltip.hover
              :title="(form.carriers && form.carriers.length > 1 || form.categoryService && form.categoryService.length > 1) ? 'Descargar archivo' : 'Buscar'"
            >
              <feather-icon 
                v-if="!loading.prices && !loading.rates && !loading.categoryService && !loading.services"
                :icon="(form.carriers && form.carriers.length > 1 || form.categoryService && form.categoryService.length > 1) ? 'DownloadIcon' : 'SearchIcon'"
              />
              <span v-if="loading.prices || loading.rates || loading.categoryService || loading.services"><b-spinner small /> </span>
            </b-button>
            <b-button
              variant="outline-light"
              v-b-tooltip.hover
              title="Limpiar filtros"
              class="ml-2"
              @click="cleanFilter"
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
          </template>
        </form-render>
        <div>
          <div class="msj-info" v-if="showTable && form.carriers.length == 1 && !(form.categoryService && form.categoryService.length > 1)">
            <li>
              Para obtener toda la información de las tarifas de un lugar de origen, puede descargarla presionando el
              botón "Exportar servicio {{ serviceSelected.name }}"
            </li>
          </div>
        </div>
        <b-form-group class="mt-2 mb-2 form-group-tariffs" v-if="showTable && form.carriers.length == 1 && !(form.categoryService && form.categoryService.length > 1)">
          <b-form-radio-group id="rates-costs-radio" @change="changeRatesCostsRadio" v-model="ratesCostsRadio" :options="ratesCostsRadioOptions" />
          <b-dropdown 
            class="ml-md-2 line-button"
            variant="success"
            right
            split
            split-variant="success"
            v-if="rateIsCurrent && showTable && form.carriers.length === 1 && !(form.categoryService && form.categoryService.length > 1) && arrayServices.length > 1"
            @click="allExport"
            :disabled="loading.getMultipleRates || loading.donwLoadExcel || loading.rates"
          >
            <template #button-content>
              <feather-icon v-if="!loading.getMultipleRates && !loading.donwLoadExcel && !loading.rates" icon="DownloadIcon"/>
              <span v-if="loading.getMultipleRates || loading.donwLoadExcel || loading.rates"><b-spinner small /> </span>
                {{$t(`Exportar ${(ratesCostsRadio === 'Costos' ? 'todos los costos' : 'todo')}` )}}
            </template>
            <b-dropdown-item 
              v-for="(service, index) in serviceTypes" 
              :key="index"
              @click="getRateForExport(service)"
            >
              {{$t(`Exportar ${(ratesCostsRadio === 'Costos' ? 'costos' : '')} ${service.name}`)}}
            </b-dropdown-item>
          </b-dropdown>
          <span
            :title="$t(buttonSend.disabled ? 'Debe completar el formulario' : (rows.length ? 'Descargar archivo' : 'No hay datos para descargar'))"
            v-b-tooltip.bottom.hover
            class="text-right button-with-alert"
            v-if="!rateIsCurrent || arrayServices.length === 1"
          >
            <b-button
              variant="success"
              @click="exportExcel"
              class="ml-2"
              :disabled="!showTable || loading.donwLoadExcel || rows.length === 0"
              v-if="showTable && form.carriers.length === 1 && !(form.categoryService && form.categoryService.length > 1)"
            >
              <feather-icon v-if="!loading.donwLoadExcel" icon="DownloadIcon" />
              <b-spinner v-if="loading.donwLoadExcel" small />
              <span class="ml-1" v-if="form.carriers.length == 1 && !(form.categoryService && form.categoryService.length > 1)">
                Exportar {{ ratesCostsRadio === 'Costos' ? `${ratesCostsRadio.toLocaleLowerCase()} ` : ''}}servicio {{ serviceSelected.name }}
              </span>
            </b-button>
          </span>
        </b-form-group>
        <ul v-if="showTable && form.categoryService && !isExpress && ratesCostsRadio !== 'Flete' && form.carriers.length == 1  && !(form.categoryService && form.categoryService.length > 1)" class="navbar-nav mb-1">
          <li>
            <div class="w-30 float-right">
              <b-input-group>
                <b-form-input
                  class="border-secondary"
                  v-model="seekerValue"
                  placeholder="ingrese el nombre del Destino"
                  cleareable
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    v-b-tooltip.hover
                    title="Buscar"
                    @click="searchSpecifically"
                    :disabled="seekerValue.length < 3"
                  >
                    <feather-icon icon="SearchIcon"
                  /></b-button>
                  <b-button variant="outline-secondary" v-b-tooltip.hover title="Limpiar" @click="cleanSearch">
                    <feather-icon icon="XIcon"
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
              <span
                v-if="seekerValue.length < 3 && seekerValue.length > 0"
                class="text-danger position-absolute bg-white"
                >{{ $t('msg-nchars', { key: 3 }) }}
              </span>
            </div>
          </li>
        </ul>
        <div class="d-flex" v-if="showTable && form.carriers.length == 1 && !(form.categoryService && form.categoryService.length > 1)">
          <b-tabs align="left" class="tab-primary" v-model="tabIndex">
            <b-tab v-for="(services, index) in serviceTypes" :key="index">
              <template #title>
                <div class="text-center">
                  <span v-html="services.name"></span>
                </div>
              </template>
            </b-tab>
          </b-tabs>
          <a class="ml-auto text-primary mt-1 font-weight-bold d-flex" @click="openModal">
            <span class="icon-class-tariff"><feather-icon icon="ClockIcon" class="iconClass" size="16" /></span>
            <span>Historial Tarifas</span>
          </a>
        </div>
      </template>
    </filter-swapper>
    <table-render
      v-if="
        showTable &&
        !loading.prices &&
        !isExpress &&
        ratesCostsRadio !== 'Flete'
      "
      :schema.sync="schema"
      :rows="rows"
      :stickyHeader="`calc(100vh - 2.5rem)`"
      id="rates-costs-table"
      :tableClass="'with-scroll'"
      :emptyHtml="emptyTableMsg"
    >
    </table-render>
    <pagination
      v-if="
        showTable &&
        !loading.prices &&
        form.categoryService &&
        !isExpress &&
        form.country.code !== 'MX' &&
        ratesCostsRadio !== 'Flete' && 
        form.carriers.length == 1 &&
        !(form.categoryService && form.categoryService.length > 1)
      "
      :pagination="pagination"
      :showSize="true"
    />
    <CoveragesKmList
      :rows="rows"
      v-if="showTable && !loading.prices && form.categoryService && !!isExpress && ratesCostsRadio !== 'Flete'"
    ></CoveragesKmList>
    <div class="table-render-skeleton" v-if="loading.prices">
      <b-skeleton-table :rows="15" :columns="schema.length || 10" />
    </div>
    <ModaltariffsHistory
      :country="countrySelected"
      @changeRateId="changeRateId"
      :rateId="rateId"
      :rates="filterRates"
      :carrier="carrierSelected"
      :service="serviceSelected"
      :form="form"
    ></ModaltariffsHistory>
    <ModalPricesOrCosts @changePricesOrCosts="changePricesOrCosts"></ModalPricesOrCosts>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModaltariffsHistory from './ModalManageVersions.vue'
import CoveragesKmList from './components/CoveragesKmList.vue'
import BaseServices from '@/store/services/index'
import ModalPricesOrCosts from '../../manage-tariffs/rates-costs/components/ModalPricesOrCosts.vue'

export default {
  name: 'TariffsMultipleCouriers',
  props: ['getCarrier'],
  components: { ModaltariffsHistory, CoveragesKmList, ModalPricesOrCosts },
  data() {
    return {
      rows: [],
      schema: [],
      buttons: [],
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      keyFormRender: 0,
      fields: [],
      form: {},
      buttonSend: {
        icon: 'SearchIcon',
        title: 'Buscar',
        color: 'warning',
        disabled: true
      },
      fieldSkipLine: {
        name: 'line',
        useSkeleton: true,
        skipLine: true,
        containerClass: 'col-12'
      },
      showTable: false,
      serviceTypes: [],
      rateServiceId: '',
      tabIndex: 0,
      filterRates: [],
      ratesCostsRadio: 'Tarifas',
      ratesCostsRadioOptions: ['Tarifas'],
      serviceIndex: 0,
      pricesOrCosts: 'prices',
      carrierSelected: null,
      serviceSelected: null,
      countrySelected: null,
      rateId: null,
      baseService: new BaseServices(this),
      seekerValue: '',
      arrayCurrentKgs: [],
      isExpress: false,
      loading: {
        rates: true,
        countries: true,
        prices: true,
        donwLoadExcel: false,
        categoryService: false,
        services: false,
        getMultipleRates: false
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      paramPage: 1,
      paramPageSize: 20,
      arrayCouriers: [],
      arrayCategoryService: [],
      arrayServices: [],
      optionsCategoryService: [],
      emptyTableMsg: 'No se encontraron registros para mostrar',
      countCategoryServices: 1,
      isExport: false,
      countCyclesServices: 0,
      rateIsCurrent: true,
      modalIsPricesOrCosts: 'prices'
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesRatesQuoter',
      places: 'getPlacesRatesQuoter',
      carriers: 'getCarriersRatesQuoter',
      categoryService: 'getCategoryServiceRatesQuoter',
      services: 'getServiceRatesQuoter',
      rates: 'getRatesRatesQuoter',
      prices: 'getPricesRatesQuoter',
      multipleRates: 'getMultipleRates',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    countries() {
      this.setCountries()
      if (!(['admin', 'superadmin'].includes(this.$session.get('cas_user').role))) {
        const indexCountry = this.fields.findIndex((el) => el.name === 'country')
        this.fields[indexCountry].disabled = true
        this.fields[indexCountry].clearable = false
        this.form.country = this.$session.get('cas_user').country
      }
    },
    carriers() {
      this.setCarriers()
    },
    categoryService() {
      this.setCategoryService()
    },
    services() {
      this.setServiceRatesQuoter()
    },
    rates() {
      if (this.isExport) {
        this.isExport = false
        this.exportExcelCurrent(this.rates.rows)
        this.loading.services = this.loading.rates
      } else {
        const existRateDifferential = this.rates.rows.some((el) => el.rate_customization_type === 'differential')
        if (existRateDifferential && !['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
          this.ratesCostsRadioOptions = ['Tarifas Generales', 'Tarifas Preferentes']
        } else {
          this.ratesCostsRadioOptions = ['Tarifas']
        }
        this.ratesCostsRadio = this.ratesCostsRadioOptions[0]
        this.setRates()
      }
    },
    multipleRates() {
      this.setMultipleRates()
    },
    prices() {
      this.setPricesRatesQuoter()
    },
    generalLoading: {
      handler() {
        this.loading.rates = false
        this.loading.countries = !!this.generalLoading.getCountriesRatesQuoter
        this.loading.prices = !!this.generalLoading.getPricesRatesQuoter
        this.loading.donwLoadExcel = !!this.generalLoading.getFileRatesQuoter
        this.loading.categoryService = !!this.generalLoading.getCategoryServiceRatesQuoter
        this.loading.services = !!this.generalLoading.getServiceRatesQuoter
      }
    },
    tabIndex(val) {
      this.serviceIndex = val
      this.serviceSelected = this.serviceTypes[val]
      const params = {
        carrier_id: this.form.carriers[0]?.id,
        service_id: this.serviceTypes[val]?.id
      }
      const queryParams = {}
      queryParams.place_code_from = this.form.places.code
      if (this.form.idSeller?.id) queryParams.organization_id = this.form.idSeller.id
      this.getServices('getRatesRatesQuoter', params, queryParams)
    },
    'pagination.page'() {
      this.paramPage = this.pagination.page
      this.getCoverages()
    },
    'pagination.limit'() {
      this.paramPageSize = this.pagination.limit
      this.getCoverages()
    },
    rateId(val) {
      const rate = this.filterRates.filter((el) => val === el.id)
      rate[0]?.rate_type === 'km' ? (this.isExpress = true) : (this.isExpress = false)
    }
  },
  mounted() {
    this.setInitialData()
    if (['marketplace', 'ecommerce'].includes(this.$session.get('cas_user').role)) {
      this.fields.splice(1, 0, this.fieldSkipLine)
      this.fields.splice(3, 2)
    }
  },
  methods: {
    setInitialData() {
      this.fields = [
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'country',
          disabled: false,
          label: 'País',
          validation: 'required',
          containerClass: 'col-12 col-md-4 col-lg-3 container-info',
          change: this.changeCountry,
          addFlags: true,
          sort: true
        },
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'places',
          label: 'Origen',
          validation: 'required',
          containerClass: 'col-12 col-md-4 col-lg-3 container-info',
          change: this.changePlaces,
          dependency: 'country',
          clearable: true,
          searchOnType: {
            fx: this.setPlaces,
            nChars: 3,
            debounce: 600,
            allowSearchAsValue: false,
            persistSearch: true
          }
        },
        this.fieldSkipLine,
        {
          fieldType: 'FieldSelect',
          useLabel: true,
          useSkeleton: false,
          type: 'text',
          name: 'idSeller',
          label: 'Id Usuario Tarifa Diferencial',
          containerClass: 'col-12 col-md-4 col-lg-3',
          dependency: 'places',
          clearable: true,
          change: this.changeIdSeller,
          searchOnType: {
            fx: this.setSeller,
            nChars: 2,
            debounce: 600,
            allowSearchAsValue: false,
            persistSearch: true
          },
          tooltip: {
            text: 'Dejar vacío para visualizar Tarifa General'
          }
        },
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'carriers',
          label: 'Courier',
          validation: 'required',
          containerClass: 'col-12 col-md-4 col-lg-3 container-info',
          change: this.changeCarriers,
          dependency: 'places',
          multiple: true,
          max: 10,
          placeholder: 'Ingrese courier',
          sort: true
        },
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'categoryService',
          label: 'Categoría de Servicio',
          containerClass: 'col-12 col-md-4 col-lg-3 container-info',
          change: this.changeCategoryService,
          dependency: 'carriers',
          options: this.optionsCategoryService,
          multiple: true,
          max: 3,
          placeholder: 'Ingrese categoría de servicio',
          sort: true
        }
      ]
      this.schema = []
      this.getServices('getCountriesRatesQuoter', null)
    },
    getCoverages() {
      this.serviceSelected = this.services.rows[this.serviceIndex]
      if (this.ratesCostsRadio === 'Tarifas' || this.ratesCostsRadio === 'Tarifas Generales' || this.ratesCostsRadio === 'Tarifas Preferentes') this.getPrices()
    },
    getPrices() {
      this.pricesOrCosts = 'prices'
      const params = this.getParams()
      if (this.filterRates.length && !this.form.idSeller?.id) {
        this.getServices('getPricesRatesQuoter', params.params, params.queryParams)
      } else if (this.filterRates.length && this.form.idSeller?.id) {
        this.getServices('getPricesRatesQuoter', params.params, params.queryParams)
      }
    },
    getParams() {
      const params = {
        carrier_id: this.form.carriers[0]?.id,
        service_id: this.serviceTypes[this.serviceIndex]?.id,
        rate_id: this.rateId,
        place_id: this.form.places.id
      }
      const rate = this.filterRates.filter((el) => this.rateId === el.id)
      const queryParams = {
        weightIntervalsLimit: rate[0]?.rate_type === 'km' ? 10 : 6,
        search: this.seekerValue,
        page: this.paramPage,
        page_size: this.paramPageSize
      }
      if (this.form.idSeller?.id) queryParams.organization_id = this.form.idSeller.id
      return { params, queryParams }
    },
    getCourierForServices(array) {
      const idsArray = array.map((el) => el.id)
      this.optionsCategoryService.filter((el) => idsArray.includes(el.id))
      return this.optionsCategoryService.filter((el) => idsArray.includes(el.id))
    },
    getMultipleFiles(val) {
      const params = {
        pricesOrCosts: this.modalIsPricesOrCosts
      }
      this.baseService.callService('postMultipleFiles', val, params)
        .then((response) => {
          this.$success(this.$t(response.message))
        })
        .catch(err => {
          this.$alert('Error al realizar la solicitud', {code: err})
        })
        .finally(() => {
          this.loading.getMultipleRates = false
        })
    },
    changeRateId(rate) {
      const params = {
        carrier_id: this.form.carriers[0]?.id,
        service_id: this.serviceTypes[this.serviceIndex].id
      }
      this.rateId = rate.id
      this.rateIsCurrent = !!(rate.type === 'current')
      const queryParams = {}
      queryParams.place_code_from = this.form.places.code
      if (this.form.idSeller?.id) queryParams.organization_id = this.form.idSeller.id
      this.getServices('getRatesRatesQuoter', params, queryParams)
    },
    changeCountry(name, value) {
      this.seekerValue = ''
      this.changePlaces()
      this.$emit('getCarrier', {})
      this.buttonSend.disabled = true
      this.optionsCategoryService = []
      this.showTable = false
      if (value?.id) {
        this.countrySelected = value
        this.form = {
          ...this.form,
          places: null,
          categoryService: null,
          carriers: null,
          idSeller: null
        }
      } else {
        this.form = {}
      }
      this.cleanPlaces(['places', 'carriers', 'categoryService'])
    },
    changePlaces(name, value) {
      this.seekerValue = ''
      this.optionsCategoryService = []
      this.showTable = false
      this.form.categoryService = null
      this.form.carriers = null
      if (value) {
        const params = {
          country_id: this.form.country.id,
          place_id: value.id
        }
        const queryParams = {}
        if (this.form.idSeller?.id) queryParams.organization_id = this.form.idSeller.id
        this.fields.forEach((el) => {
          if (el.name !== 'country' && el.name !== 'places') this.setSkeletonField(el.name, true)
        })
        this.getServices('getCarriersRatesQuoter', params, queryParams)
      } else {
        this.cleanPlaces(['places', 'carriers', 'categoryService'])
      }
    },
    changeIdSeller(name, value) {
      this.fields.forEach((el) => {
        if (el.name !== 'country' && el.name !== 'places' && el.name !== 'idSeller') this.setSkeletonField(el.name, true)
      })
      this.showTable = false
      this.form = {
        ...this.form,
        categoryService: null,
        carriers: null
      }
      const params = {
        country_id: this.form.country.id,
        place_id: this.form.places.id
      }
      const queryParams = {}
      if (value) {
        queryParams.organization_id = value.id
        this.$store.dispatch('fetchService', { name: 'getCarriersRatesQuoter', params, queryParams })
      } else {
        this.$store.dispatch('fetchService', { name: 'getCarriersRatesQuoter', params })
        this.cleanPlaces(['carriers', 'categoryService'])
      }
    },
    changeCarriers(name, value) {
      if (value.length) this.buttonSend.disabled = false
      else this.buttonSend.disabled = true
      this.form = {
        ...this.form,
        categoryService: null
      }
      this.serviceTypes = []
      this.seekerValue = ''
      this.$emit('getCarrier', {})
      this.showTable = false
      const couriers = this.checkActionAddOrDelete(value, 'arrayCouriers')
      if (couriers.length) {
        this.setSkeletonField('categoryService', true)
        couriers.forEach((el) => {
          const params = {
            country_id: this.form.country.id,
            place_id: this.form.places.id,
            carrier_id: el.id
          }
          const queryParams = {}
          if (this.form.idSeller?.id) queryParams.organization_id = this.form.idSeller.id
          this.$store.dispatch('fetchService', { name: 'getCategoryServiceRatesQuoter', params, queryParams })
        })
      }
    },
    changeCategoryService(name, value) {
      this.seekerValue = ''
      this.rateId = null
      if (this.form.carriers.length === 1) this.showTable = false
      let categoryServices = []
      if (this.form.categoryService?.length) {
        if (this.form.categoryService?.length === 1) {
          this.countCyclesServices = 0
          this.arrayServices = []
          this.arrayCategoryService = []
        }
        categoryServices = this.checkActionAddOrDelete(value, 'arrayCategoryService')
      } else {
        categoryServices = value
      }
      if (categoryServices.length) {
        this.countCategoryServices = 1
        const allCouriersServices = new Set(this.getCourierForServices(categoryServices).map(JSON.stringify)) // Eliminar objetos repetidos
        this.filteredCouriersServices = Array.from(allCouriersServices, JSON.parse)
        this.filteredCouriersServices.forEach(el => {
          const params = {
            country_id: this.form.country.id,
            place_id: this.form.places.id,
            carrier_id: el.courier,
            service_type_id: el.id
          }
          const queryParams = {}
          if (this.form.idSeller?.id) queryParams.organization_id = this.form.idSeller.id
          this.$store.dispatch('fetchService', { name: 'getServiceRatesQuoter', params, queryParams })
        })
        this.countCyclesServices = this.countCyclesServices + this.filteredCouriersServices.length
      }
      this.buttonSend.disabled = false
    },
    changeSchema(data) {
      if (data.length) {
        this.arrayCurrentKgs = Object.keys(data[0].weight_intervals)
        this.arrayCurrentKgs = this.arrayCurrentKgs.sort(function (a, b) {
          return parseFloat(a) - parseFloat(b)
        })
        this.schema = [
          {
            label: 'Tiempo de tránsito',
            key: 'transit_days',
            class: ['text-center']
          }
        ]
        this.arrayCurrentKgs.forEach((el) => {
          this.schema.push({
            label: `Precio (${el}kg)`,
            key: `${el}kg`,
            class: ['text-right']
          })
        })
        if (this.form.country.id === 1) {
          // Confirma si el país es Chile
          this.schema.unshift({ label: 'Comuna', key: 'level0' })
          this.schema.unshift({ label: 'Región', key: 'level2' })
        } else if (this.form.country.id === 3) {
          // Confirma si el país es Colombia
          this.schema.unshift({ label: 'Municipio', key: 'level0' })
          this.schema.unshift({ label: 'Departamento', key: 'level2' })
          this.schema.unshift({ label: 'Código', key: 'dest_place_code' })
        } else if (this.form.country.id === 4) {
          // Confirma si el país es Perú
          this.schema.unshift({ label: 'Distrito', key: 'level1' })
          this.schema.unshift({ label: 'Provincia', key: 'level2' })
          this.schema.unshift({ label: 'Departamento', key: 'level3' })
          this.schema.unshift({ label: 'Ubigeo', key: 'level0' })
        } else if (this.form.country.id === 2) {
          // Confirma si el país es México
          this.schema.unshift({ label: 'Municipio', key: 'level1' })
          this.schema.unshift({ label: 'Estado', key: 'level2' })
          this.schema.unshift({ label: 'CP', key: 'level0' })
        }
      }
    },
    changeRatesCostsRadio(name) {
      this.ratesCostsRadio = name
      this.setRates()
    },
    setCountries() {
      const index = this.fields.findIndex((el) => el.name === 'country')
      let countryOptions = []
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        countryOptions = this.countries.rows
      } else if (['admin', 'superadmin', 'ecommerce', 'marketplace'].includes(this.$session.get('cas_user').role)) {
        countryOptions = this.countries.rows.filter((el) => 
        // se limita a estos 4 países ya que si en el futuro se agrega otro país debe ser verificado para ser liberado a los clientes
          el.code === 'MX' || el.code === 'CO' || el.code === 'CL' || el.code === 'PE'
        )
      } else {
        const index = this.fields.findIndex((el) => el.name === 'idSeller')
        if (index !== -1) this.fields.splice(index, 1)
      }
      this.fields[index].options = countryOptions
      this.fields.forEach((el) => this.setSkeletonField(el.name, false))
    },
    setPlaces(value) {
      this.$emit('getCarrier', {})
      this.buttonSend.disabled = true
      this.showTable = false
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {
        search: value
      }
      return this.baseService
        .callService('getPlacesRatesQuoter', queryParams, params)
        .then((resp) => {
          return resp.data.places.map((el) => ({
            ...el,
            text: el.full_name,
            valid: true
          }))
        })
        .catch((err) => {
          return err
        })
    },
    setCarriers() {
      // Eliminar objetos repetidos
      const rows = []
      // Paso 1: Iterar sobre el array original
      this.carriers.rows.forEach(el => {  
        // Paso 2: Verificar si el objeto ya existe en el nuevo array
        let exist = false
        rows.forEach(item => {
          if (JSON.stringify(item) === JSON.stringify(el)) {
            exist = true
          }
        })
        // Paso 3: Si el objeto no existe, agregarlo al nuevo array
        if (!exist) {
          rows.push(el)
        }
      })
      const inputs = ['idSeller', 'carriers', 'categoryService']
      inputs.forEach((el) => this.setSkeletonField(el, true))
      const index = this.fields.findIndex((el) => el.name === 'carriers')
      this.fields[index].options = rows
      setTimeout(() => inputs.forEach((el) => this.setSkeletonField(el, false)), 100)
    },
    setCategoryService() {
      const index = this.fields.findIndex((el) => el.name === 'categoryService')
      this.optionsCategoryService = this.optionsCategoryService.concat(this.categoryService.rows.map((el) => ({ ...el, courier: this.carrierSelected.id })))
      const options = Array.from(new Map(this.optionsCategoryService.map(item => [item.name, item])).values())
      this.fields[index].options = options
      this.setSkeletonField('categoryService', false)
    },
    setServiceRatesQuoter() {
      if (this.services.rows.length) {
        this.services.rows.map((el) => {
          if (!this.arrayServices.includes(el.id)) this.arrayServices.push(el.id)
        })
        if (this.form.carriers.length === 1 && !this.form.categoryService?.length >= 1) {
          const allServices = new Set(this.serviceTypes.concat(this.services.rows).map(JSON.stringify))
          this.serviceTypes = Array.from(allServices, JSON.parse)
        } else {
          this.serviceTypes = this.services.rows
        }
        this.buttonSend.disabled = false
        if ((this.optionsCategoryService.length === this.countCategoryServices) && this.form.carriers.length === 1) {
          return this.getRates()
        } else if ((this.countCategoryServices === this.countCyclesServices) && !this.form.categoryService?.length) {
          return this.generateMultipleFile()
        } else {
          this.countCategoryServices++
        }
      }
    },
    setRates() {
      this.loading.services = this.loading.rates
      if (this.ratesCostsRadio === 'Tarifas Preferentes' || this.form.idSeller?.id) {
        this.filterRates = this.rates.rows.filter((el) => (el.rate_customization_type === 'differential'))
      } else {
        this.filterRates = this.rates.rows.filter((el) => (el.rate_customization_type === 'general'))
      }
      this.rateServiceId = this.filterRates.find((el) => el.type === 'current') || this.filterRates[0]
      this.rateId = this.filterRates?.some((el) => el.id === this.rateId) ? this.rateId : this.rateServiceId.id
      if (this.ratesCostsRadioOptions.includes(this.ratesCostsRadio)) {
        this.getCoverages()
      } else {
        this.ratesCostsRadio = 'Tarifas'
      }
    },
    setSkeletonField(name, bool) {
      const index = this.fields.findIndex((el) => el.name === name)
      if (index !== -1) {
        this.fields[index].useSlot = bool
        this.fields[index].useSkeleton = bool
        this.fields[index].useLabel = bool
      }
    },
    setPricesRatesQuoter() {
      this.rows = []
      let currentId = 0
      if (!this.isExpress) this.changeSchema(this.prices.rows)
      this.paramPage = this.prices.meta?.current_page || 1
      this.pagination.total = this.prices.meta?.total_rows || 0
      if (this.form.country.code === 'CO') {
        const indexFreight = this.schema.findIndex((el) => el.key === 'freight_minimum_cost')
        const indexPercentage = this.schema.findIndex((el) => el.key === 'freight_percentage_cost')
        const freight_minimum_cost = {
          label: 'Flete de manejo minimo',
          key: 'freight_minimum_cost',
          class: ['text-right']
        }
        const freight_percentage_cost = {
          label: '% flete de manejo',
          key: 'freight_percentage_cost',
          class: ['text-center']
        }
        if (indexFreight === -1) this.schema.splice(3, 0, freight_minimum_cost)
        if (indexPercentage === -1) this.schema.splice(4, 0, freight_percentage_cost)
      } else {
        const indexFreight = this.schema.findIndex((el) => el.key === 'freight_minimum_cost')
        if (indexFreight !== -1) this.schema.splice([indexFreight], 1)
        const indexPercentage = this.schema.findIndex((el) => el.key === 'freight_percentage_cost')
        if (indexPercentage !== -1) this.schema.splice([indexPercentage], 1)
      }
      if (this.isExpress) {
        [this.prices.rows.weight_intervals].map((el) => {
          const keysRows = Object.keys(el)
          keysRows.map((key) => {
            this.prices.rows.weight_intervals[key] = this.prices.rows.weight_intervals[key].fp
          })
        })
        this.rows = [this.prices.rows]
        if (this.rows.length) this.rows[0].weight_intervals.price = 'Precio'
      } else {
        this.prices.rows.map(({ weight_intervals, freight_percentage_cost, dest_place_name, ...rest }) => {
          const percentage = `${freight_percentage_cost} %`
          const levelsPlace = dest_place_name.split(', ')
          let level0MX = ''
          if (this.form.country.id === 2) level0MX = levelsPlace[0].replace('CP ', '')
          const row = {
            id: currentId,
            ...rest,
            freight_percentage_cost: percentage,
            level0: level0MX || levelsPlace[0],
            level1: levelsPlace[1],
            level2: levelsPlace[2],
            level3: levelsPlace[3]
          }
          this.arrayCurrentKgs.forEach((el) => {
            row[`${el}kg`] = weight_intervals[el]?.fp
          })
          this.rows.push(row)
          currentId++
        })
      }
      this.showTable = true
    },
    setSeller(value) {
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {
        search: value,
        include_shippers: true
      }
      return this.baseService
        .callService('getSeller', queryParams, params)
        .then((resp) => {
          return resp.data.organizations.map((el) => ({
            ...el,
            text: el.fullname,
            valid: true
          }))
        })
        .catch((err) => {
          return err
        })
    },
    setMultipleRates() {
      const data = {data: this.multipleRates.rows}
      data.place_id_from = this.form.places.id
      this.getMultipleFiles(data)
    },
    checkActionAddOrDelete(value, arrayName) {
      const deletedItems = this[arrayName].filter(el => !value.includes(el))
      const addedElements = value.filter(elemento => !this[arrayName].includes(elemento))
      if (addedElements.length) {
        this.carrierSelected = addedElements[0]
      }
      if (deletedItems.length) {
        this.categoryService.rows = []
        this.optionsCategoryService = this.optionsCategoryService.filter(el => el.courier !== deletedItems[0].id)
        this.arrayServices = this.arrayServices.filter(el => el !== deletedItems[0].id)
        this.setCategoryService()
      }
      this[arrayName] = value
      return addedElements
    },
    cleanPlaces(fieldsArray) {
      fieldsArray.forEach((el) => this.setSkeletonField(el, true))
      setTimeout(() => {
        fieldsArray.forEach((el) => this.setSkeletonField(el, false))
      }, 200)
    },
    getSchemaMultipleCouriers() {
      switch (this.form.country.code.toLocaleLowerCase()) {
      case 'cl':
        this.schema = [
          { label: 'Región', key: 'region', class: ['text-center'] },
          { label: 'Comuna', key: 'comuna', class: ['text-center']},
          { label: 'Tiempo De Tránsito', key: 'transit_days', class: ['text-center']}
        ]
        break
      case 'co' :
        this.schema = [
          { label: 'Código', key: 'codigo', class: ['text-center'] },
          { label: 'Departamento', key: 'departamento', class: ['text-center']},
          { label: 'Municipio', key: 'municipio', class: ['text-center']},
          { label: 'Flete de manejo minimo', key: 'freight_minimum_cost', class: ['text-center'] },
          { label: '% flete de manejo', key: 'freight_percentage_cost', class: ['text-center'] },
          { label: 'Tiempo De Tránsito', key: 'transit_days', class: ['text-center'] }
        ]
        break
      case 'mx' :
        this.schema = [
          { label: 'CP', key: 'cp', class: ['text-center'] },
          { label: 'Estado', key: 'estado', class: ['text-center']},
          { label: 'Municipio', key: 'municipio', class: ['text-center']},
          { label: 'Tiempo De Tránsito', key: 'transit_days', class: ['text-center'] }
        ]
        break
      case 'pe' :
        this.schema = [
          { label: 'Ubigeo', key: 'ubigeo', class: ['text-center'] },
          { label: 'Departamento', key: 'departamento', class: ['text-center']},
          { label: 'Provincia', key: 'provincia', class: ['text-center']},
          { label: 'Distrito', key: 'distrito', class: ['text-center']},
          { label: 'Tiempo De Tránsito', key: 'transit_days', class: ['text-center'] }
        ]
        break
      }
      this.schema.push(
        { label: 'Precio (1kg)', key: 'price_1', class: ['text-center'] },
        { label: 'Precio (2kg)', key: 'price_2', class: ['text-center'] },
        { label: 'Precio (3kg)', key: 'price_3', class: ['text-center'] },
        { label: 'Precio (4kg)', key: 'price_4', class: ['text-center'] },
        { label: 'Precio (5kg)', key: 'price_5', class: ['text-center'] },
        { label: 'Precio (6kg)', key: 'price_6', class: ['text-center'] }
      )
    },
    multipleCouriersOrCategoryService() {
      const options = Array.from(new Map(this.optionsCategoryService.map(item => [item.name, item])).values())
      if (!this.form.categoryService?.length) options.splice(0, 3).forEach(el => this.changeCategoryService('', [el]))
      this.getSchemaMultipleCouriers()
      this.rows = []
      this.emptyTableMsg = '<p>En este momento estamos preparando el archivo, revisa tu correo en unos minutos.</p>'
      this.showTable = true
    },
    generateMultipleFile() {
      const queryParams = {
        service_ids: this.arrayServices,
        country: this.form.country.code,
        place_id_from: this.form.places.id,
        place_code_from: this.form.places.code,
        company_id: this.form.idSeller?.id
      }
      this.setSkeletonField('categoryService', false)
      this.loading.getMultipleRates = true
      this.$store.dispatch('fetchService', { name: 'getMultipleRates', params: {}, queryParams })
    },
    onlyOneCourier() {
      this.optionsCategoryService.forEach(el => this.changeCategoryService('', [el]))
    },
    getRates(service) {
      this.$emit('getCarrier', this.form.carriers[0]?.id)
      const params = {
        carrier_id: this.form.carriers[0]?.id,
        service_id: service?.id || this.serviceTypes[this.serviceIndex]?.id
      }
      const queryParams = {}
      queryParams.place_code_from = this.form.places.code
      if (this.form.idSeller?.id) queryParams.organization_id = this.form.idSeller.id
      this.loading.services = true
      this.getServices('getRatesRatesQuoter', params, queryParams)
    },
    getRateForExport(service) {
      this.isExport = true
      this.getRates(service)
    },
    buttonFind() {
      this.countCyclesServices = 0
      if (this.form.carriers?.length > 1 && !this.form.categoryService?.length) {
        return this.multipleCouriersOrCategoryService()
      } else if (this.form.carriers?.length === 1 && this.form.categoryService?.length === 1) {
        return this.getRates()
      } else if (this.form.carriers?.length && this.form.categoryService?.length >= 1) {
        return this.generateMultipleFile()
      }
      if (this.form.carriers?.length && !this.form.categoryService?.length) {
        return this.onlyOneCourier()
      }
      this.getRates()
    },
    searchSpecifically() {
      this.pagination.page === 1 ? this.getCoverages() : (this.pagination.page = 1)
    },
    exportExcelCurrent(rates) {
      const rate = rates.filter((el) => el.type === 'current')
      const service = this.arrayServices.filter((el) => el === rate[0].carrier_service_id)
      if (this.filterRates.length) {
        const params = {
          carrier_id: this.form.carriers[0]?.id,
          service_id: rate[0].carrier_service_id,
          rate_id: rate[0].id,
          place_id: this.form.places.id,
          pricesOrCosts: this.pricesOrCosts,
          carrier_name: this.form.carriers[0]?.name,
          places_name: this.form.places.name,
          service_name: service[0].name
        }
        const queryParams = {}
        if (this.form.idSeller?.id) queryParams.organization_id = this.form.idSeller.id
        this.$store.dispatch('getFiles', { name: 'getFileRatesQuoter', params, queryParams })
      }
    },
    exportExcel() {
      if (this.filterRates.length) {
        const params = {
          carrier_id: this.form.carriers[0]?.id,
          service_id: this.services.rows[this.serviceIndex].id,
          rate_id: this.rateId,
          place_id: this.form.places.id,
          pricesOrCosts: this.pricesOrCosts,
          carrier_name: this.form.carriers[0]?.name,
          places_name: this.form.places.name,
          service_name: this.services.rows[this.serviceIndex].name
        }
        const queryParams = {}
        if (this.form.idSeller?.id) queryParams.organization_id = this.form.idSeller.id
        this.$store.dispatch('getFiles', { name: 'getFileRatesQuoter', params, queryParams })
      }
    },
    allExport() {
      this.generateMultipleFile()
    },
    changePricesOrCosts(val) {
      this.modalIsPricesOrCosts = val
      this.buttonFind()
    },
    openModalPricesOrCosts() {
      this.$bvModal.show('ModalPricesOrCosts')
    },
    cleanFilter() {
      this.rateId = null
      this.form = {}
      this.showTable = false
      this.buttonSend.disabled = true
    },
    openModal() {
      this.$bvModal.show('ModaltariffsHistory')
    },
    getServices(name, params, queryParams) {
      this.$store.dispatch('fetchService', { name, params, queryParams })
    },
    cleanSearch() {
      this.seekerValue = ''
      this.getCoverages()
    }
  }
}
</script>
<style>
.is-disabled {
  background-color: #f8f8f8 !important;
  opacity: 0.5;
}
.buttons-rates-costs {
  height: fit-content;
}
.form-group-tariffs > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
