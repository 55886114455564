<template>
  <div>
    <div v-if="loading.allcarrier">
      <b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" />
      <div class="table-skeleton-tableQuoter">
        <b-skeleton-table :rows="5" :columns="6" :table-props="{ }" />
      </div>
    </div>
    <div class="filterComparator">
        <filter-select-group
          :options="optionsValues"
          :onChange="handleChangeFilter"
          :loadingStatus="statusRequest"
          dropdownText="Ordenar por"
          :selectDefault="selectDefault"
        />
      </div>
    <div v-show="!loading.allcarrier">
      <b-table
        responsive="sm"
        :key="keyTableRender"
        :items="rows"
        :fields="schema"
        borderless="borderless"
        tbody-tr-class="comparatorTableShowMoreBody"
        show-empty
        class="comparatorTableShowMore"
      >
        <template #empty="">
          <span class="text-center w-full push-left">{{ 'No se encontraron datos para mostrar' }}</span>
        </template>
        <!-- Destinos (head) -->
        <template #head()="data">
          <span>{{ data.label }}</span>
        </template>
        <!-- Carriers (body) -->
        <template #cell(destination)="data">
          <span v-if=" data.item.code" class="text-center">
            <img
              width="120px"
              :alt="data.field.imgSrc"
              :src="`https://storage.googleapis.com/enviame-misc/carriers/logos/color/${data.item.code.toLowerCase()}.png`"
            />
          </span>
        </template>
        <!-- Precios (body) -->
        <template #cell()="scope">
          <span
            :class="[scope.field.minValue === scope.item[`price${scope.item[`${scope.field.key}`]}`] ? 'minValue' : '', 'allValues']"
            v-if="scope.item.hasOwnProperty([`price_formatted${scope.item[scope.field.key]}`])"
            >{{ scope.item[`price_formatted${scope.item[scope.field.key]}`] }}&nbsp;
            <feather-icon
              v-b-tooltip.html
              custom-class="tooltipCustomClass"
              :title="scope.item[`transit_days${scope.field.destination_id}`]"
              icon="ClockIcon"
            />
          </span>
          <span v-else-if="scope.field.key === 'separation'"></span>
          <span v-else>-</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'select_destinations', 'loading'],
  data() {
    return {
      rows: [],
      schema: [],
      keyTableRender: 0,
      parsedResponse: [],
      showSecondTable: false,
      statusRequest: null,
      optionsValues: [],
      selectDefault: {},
      dataMeta: [],
      destination: null,
      order: null
    }
  },
  watch: {
    data () {
      this.parsedResponse = []
      this.rows = []
      this.schema = []
      this.setInitialData()
    }
  },
  methods: {
    setInitialData() {
      this.optionsValues = this.chargesOptionsFilter(this.select_destinations)
      this.selectDefault = {0: this.optionsValues[0].values[0].item, 1: this.optionsValues[1].values[0].item}
      this.destination = this.optionsValues[0].values[0].item
      this.order = this.optionsValues[1].values[0].item
      if (!this.data.data.length) return
      // Crear el head de la tabla
      this.schema = [
        {label: '', key: 'destination', useSlot: true, class: ['text-center', 'comparatorTableShowMoreHead']},
        {label: '', useSlot: true, key: 'separation', class: ['separationQuoter-thead']}
      ]
      // Crear el body de la tabla
      this.dataMeta = [...this.data.meta.carriers]
      this.dataMeta.forEach(data => {
        this.data.data.forEach(dataRep => {
          if (data.id === dataRep.carrier_id) {
            data[`destination_id${dataRep.to_place_id}`] = dataRep.to_place_id
            data[`price_formatted${dataRep.to_place_id}`] = dataRep.price_formatted
            data[`price${dataRep.to_place_id}`] = dataRep.price
            const transitDays = dataRep.transit_days?.split(',')
            data[`transit_days${dataRep.to_place_id}`] = dataRep.service_names.split(',').map((nameServ, i) => {
              let serviceName = `<strong>&#x2022; Servicio ${nameServ}</strong>`
              if (transitDays) serviceName += `<strong>:</strong> ${transitDays[i]} ${(transitDays[i] <= 1) ? 'día hábil' : 'días hábiles'}<br>`
              return serviceName
            }).join('')
            data.separation = 'separation'
          }
        })
      })
      // Agrega el head dinamico (segun destinos) a la tabla
      this.select_destinations.forEach(({id, name, minVal}) => {
        this.parsedResponse.push({
          'destination_id': id,
          'name_destination': name
        })
        this.schema.push({
          label: name,
          key: `destination_id${id}`,
          destination_id: id,
          useCarrierLogo: true,
          class: ['text-center', 'comparatorTableShowMoreHead'],
          useSlot: true,
          minValue: minVal,
          useDangerHTML: true
        })
      })
      this.orderRows()
      this.rows = this.dataMeta
      this.keyTableRender++
    },
    chargesOptionsFilter(select_destinations) {
      const destinationsValues = select_destinations.map(({id, name}) => (
        {item: {value: id, name}}
      ))
      return [
        {
          title: 'Destino',
          values: destinationsValues
        },
        {
          title: 'Orden',
          values: [
            { item: {value: 0, name: 'Menor precio'} },
            { item: {value: 1, name: 'Mayor precio'} }
          ]
        }
      ]
    },
    handleChangeFilter(values) {
      const changeDestination = this.optionsValues[0].values.find(({item}) => item.value === values[-1].value)
      const changeOrder = this.optionsValues[1].values.find(({item}) => item.value === values[-1].value)
      if (changeDestination) {
        this.destination = changeDestination.item
      } else if (changeOrder) {
        this.order = changeOrder.item
      }
      this.orderRows()
      this.statusRequest = 'load'
    },
    orderRows() {
      this.dataMeta.sort((a, b) => {
        if (this.order.name === 'Menor precio') return (a[`price${this.destination.value}`] || Infinity) - (b[`price${this.destination.value}`] || Infinity)
        if (this.order.name === 'Mayor precio') return (b[`price${this.destination.value}`] || -Infinity) - (a[`price${this.destination.value}`] || -Infinity)
      })
      this.rows = this.dataMeta
      this.keyTableRender++
      setTimeout(() => {
        this.statusRequest = 'success'
      }, 300)
    }
  }
}
</script>

<style lang="scss">
.minValue{
  color: #28a745 !important;
}
.allValues{
  color: #6e6b7b7a;
  font-weight: 600;
}
th.comparatorTableShowMoreHead, .separationQuoter-thead{
  background-color: white !important;
}
.comparatorTableShowMoreBody {
  background-color: #F0F7FA !important;
}
.tooltip-inner{
  text-align: start !important;
  max-width: max-content !important;
  padding: 10px;
}
.comparatorTableShowMore {
  tbody:first-child{
    border-top: 1px solid #E5E5E5 !important;
  }
  tbody tr td:first-child {
    border-left: 1px solid #4A7BBE !important;
    border-right: 1px solid #4A7BBE !important;
    width: 30%;
  }
  tbody tr:first-child td:first-child {
    border-top: 1px solid #4A7BBE;
  }
  tbody tr:last-child td:first-child {
    border-bottom: 1px solid #4A7BBE !important;
  }
  // tbody tr:first-child td:last-child {
  //   border-top: 1px solid #4A7BBE;
  // }
  tbody tr:first-child td:not(.separationQuoter-thead) {
    border-top: 1px solid #4A7BBE;
  }
  tbody tr:last-child td:not(.separationQuoter-thead) {
    border-bottom: 1px solid #4A7BBE;
  }
  tbody tr td:last-child {
    border-right: 1px solid #4A7BBE;
  }
  tbody .separationQuoter-thead {
    border-right: 1px solid #4A7BBE;
  }
  thead {
    position: relative;
    z-index: -1;
    color: transparent;
    display: none;
  }
  .separationQuoter-thead{
    padding: 4px !important;
  }
}
.filterComparator {
  display: flex;
  justify-content: right;
  right: 3em;
}
</style>
