<template>
  <div>
    <div v-if="loading.callingService">
      <b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" />
      <div class="table-skeleton-tableQuoter">
        <b-skeleton-table :rows="5" :columns="6" :table-props="{ }" />
      </div>
    </div>
    <div v-if="!loading.callingService">
      <span class="button-export-comparator" v-if="$session.get('cas_user').permissions['coverage.*'] || $session.get('cas_user').role === 'admin'">
        <b-button
          variant="outline-success"
          @click="exportExcel"
          class="mb-2 justify-content-right align-items-right"
          :disabled="loadingComparatorList.donwLoadExcel"
          v-if="showExportButton"
        >
          <feather-icon v-if="!loadingComparatorList.donwLoadExcel" icon="DownloadIcon" />
          <b-spinner v-if="loadingComparatorList.donwLoadExcel" small class="ml-2 mr-2"/>
          <span class="name-button" v-if="!loadingComparatorList.donwLoadExcel">Exportar</span>
        </b-button>
      </span>
      <b-table
        responsive="sm"
        :key="keyTableRender"
        :items="rows"
        :fields="schema"
        borderless="borderless"
        tbody-tr-class="comparatorTableBody"
        show-empty
        class="comparatorTable"
      >
        <template #empty="">
          <span class="text-center w-full push-left">{{ 'No se encontraron datos para mostrar' }}</span>
        </template>
        <!-- Destinos (head) -->
        <template #head()="data">
          <span>{{ data.label }}</span>
        </template>
        <!-- Carriers (body) -->
        <template #cell(destination)="data">
          <span v-if=" data.item.code" class="text-center">
            <img
              width="120px"
              :alt="data.field.imgSrc"
              :src="`https://storage.googleapis.com/enviame-misc/carriers/logos/color/${data.item.code.toLowerCase()}.png`"
            />
          </span>
        </template>
        <!-- Precios (body) -->
        <template #cell()="scope">
          <span
            :class="[scope.field.minValue === scope.item[`price${scope.item[`${scope.field.key}`]}`] ? 'minValue' : '', 'allValues']"
            v-if="scope.item.hasOwnProperty([`price_formatted${scope.item[scope.field.key]}`])"
            >{{ scope.item[`price_formatted${scope.item[scope.field.key]}`] }}&nbsp;
            <feather-icon
              v-b-tooltip.html
              custom-class="tooltipCustomClass"
              :title="scope.item[`transit_days${scope.field.destination_id}`]"
              icon="ClockIcon"
            />
          </span>
          <span v-else-if="scope.field.key === 'separation'"></span>
          <span v-else>-</span>
        </template>
      </b-table>
      <div>
        <b-button
          id="showMoreComparator"
          variant="outline-light"
          class="showMoreComparator"
          @click="showMoreComparator"
        >
          <span  v-if="!showSecondTable">
            Ver más
            <feather-icon icon="ChevronDownIcon" size="15" />
          </span>
          <span  v-if="showSecondTable">
            Ver Menos
            <feather-icon icon="ChevronUpIcon" size="15" />
          </span>
        </b-button>
        <comparator-list-show-more
          v-if="showSecondTable"
          id="ComparatorListShowMore"
          :data="data_2"
          :select_destinations="select_destinations"
          :loading="loading"
        ></comparator-list-show-more>
      </div>
    </div>
  </div>
</template>

<script>
import ComparatorListShowMore from './ComparatorListShowMore.vue'
import { mapGetters } from 'vuex'

export default {
  components: { ComparatorListShowMore },
  props: ['data', 'data_2', 'select_destinations', 'sendForm', 'params', 'loading', 'queryParams'],
  data() {
    return {
      rows: [],
      schema: [],
      keyTableRender: 0,
      parsedResponse: [],
      showSecondTable: false,
      loadingComparatorList: {
        donwLoadExcel: false
      },
      showExportButton: false
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading'
    })
  },
  watch: {
    data () {
      this.parsedResponse = []
      this.rows = []
      this.schema = []
      this.setInitialData()
    },
    generalLoading: {
      handler() {
        this.loadingComparatorList.donwLoadExcel = !!this.generalLoading.getFileComparator
      }
    }
  },
  methods: {
    setInitialData() {
      this.showExportButton = !!this.data.data.length
      if (!this.data.data.length) return
      // Crear el head de la tabla
      this.schema = [
        {label: '', key: 'destination', useSlot: true, class: ['text-center', 'comparatorTableHead']},
        {label: '', useSlot: true, key: 'separation', class: ['separationQuoter-thead']}
      ]
      // Crear el body de la tabla
      const dataMeta = [...this.data.meta.carriers]
      dataMeta.forEach(data => {
        this.data.data.forEach(dataRep => {
          if (data.id === dataRep.carrier_id) {
            data[`destination_id${dataRep.to_place_id}`] = dataRep.to_place_id
            data[`price_formatted${dataRep.to_place_id}`] = dataRep.price_formatted
            data[`price${dataRep.to_place_id}`] = dataRep.price
            const transitDays = dataRep.transit_days?.split(',')
            data[`transit_days${dataRep.to_place_id}`] = dataRep.service_names.split(',').map((nameServ, i) => {
              let serviceName = `<strong>&#x2022; Servicio ${nameServ}</strong>`
              if (transitDays) serviceName += `<strong>:</strong> ${transitDays[i]} ${(transitDays[i] <= 1) ? 'día hábil' : 'días hábiles'}<br>`
              return serviceName
            }).join('')
            data.separation = 'separation'
          }
        })
      })
      // Encontrar el valor mínimo por destino
      this.select_destinations.forEach(data => {
        dataMeta.forEach(dataRep => {
          data.minVal = this.getSmallValue(data.minVal, dataRep[`price${data.id}`])
        })
      })
      // Agrega el head dinamico (segun destinos) a la tabla
      this.select_destinations.forEach(({id, name, minVal}) => {
        this.parsedResponse.push({
          'destination_id': id,
          'name_destination': name
        })
        this.schema.push({
          label: name,
          key: `destination_id${id}`,
          destination_id: id,
          useCarrierLogo: true,
          class: ['text-center', 'comparatorTableHead'],
          useSlot: true,
          minValue: minVal,
          useDangerHTML: true
        })
      })
      // Ordenar los datos por precio
      dataMeta.sort((a, b) => {
        return (a[`price${this.select_destinations[0].id}`] || Infinity) - (b[`price${this.select_destinations[0].id}`] || Infinity)
      })
      this.rows = dataMeta
      this.showSecondTable = false
      this.keyTableRender++
    },
    getSmallValue (oldPrice, newPrice) {
      return (!oldPrice || (parseFloat(oldPrice) > parseFloat(newPrice))) ? newPrice : oldPrice
    },
    showMoreComparator () {
      this.showSecondTable = !this.showSecondTable
      const excludeIds = this.data.meta.carriers.map(el => el.id)
      if (this.showSecondTable) this.$emit('sendForm', excludeIds)
    },
    exportExcel() {
      this.$store.dispatch('getFiles', { name: 'getFileComparator', params: this.params, queryParams: this.queryParams })
    }
  }
}
</script>

<style lang="scss">
.minValue{
  color: #28a745 !important;
}
.allValues{
  color: #6e6b7b7a;
  font-weight: 600;
}
th.comparatorTableHead, .separationQuoter-thead{
  background-color: white !important;
}
.separationQuoter-thead {
  width: 8px !important;
  max-width: 8px !important;
  padding: 0 !important;
  margin: 0 !important;
}
.comparatorTableBody {
  background-color: #F0F7FA !important;
}
.tooltip-inner{
  text-align: start !important;
  max-width: max-content !important;
  padding: 10px;
}
.comparatorTable {
  tbody:first-child{
    border-top: 1px solid #E5E5E5 !important;
  }
  tbody tr td:first-child {
    border-left: 1px solid #4A7BBE !important;
    border-right: 1px solid #4A7BBE !important;
    width: 30%;
  }
  tbody tr:first-child td:first-child {
    border-top: 1px solid #4A7BBE;
  }
  tbody tr:last-child td:first-child {
    border-bottom: 1px solid #4A7BBE !important;
  }
  tbody tr:first-child td:not(.separationQuoter-thead) {
    border-top: 1px solid #4A7BBE;
  }
  tbody tr:last-child td:not(.separationQuoter-thead) {
    border-bottom: 1px solid #4A7BBE;
  }
  tbody tr td:last-child {
    border-right: 1px solid #4A7BBE;
  }
  tbody .separationQuoter-thead {
    border-right: 1px solid #4A7BBE;
  }
  thead tr th:nth-child(3) {
    border-left: 1px solid #4A7BBE;
  }
  thead tr th:not(:first-child):not(:nth-child(2)) {
    border-top: 1px solid #4A7BBE;
  }
  thead tr th:last-child {
    border-right: 1px solid #4A7BBE;
    border-top-right-radius: 10px;
  }
  .separationQuoter-thead{
    padding: 0px !important;
  }
  thead {
    color: #4A7BBE !important;
  }
}
.showMoreComparator{
  margin: 1em;
  border: inherit !important;
  font-weight: 800;
}
.showMoreComparator:hover{
  background-color: white !important;
  color: #4A7BBE !important;
}
.button-export-comparator{
  display: flex;
  justify-content: right;
  padding-right: 5px;
}
.name-button{
  margin-left: 0.5em;
}
</style>
