<template>
    <b-modal 
      centered 
      id="ModalPricesOrCosts" 
      size="sm"
      modal-class="dialog-400"
      :title="$t('Confirmar')"
      ok-variant="success"
      @ok="onAccept"
    >
      <p class="mb-3 mt-1 form-modal-prices-or-costs">Para búsquedas combinadas de múltiples couriers y servicios, enviaremos a tu correo un enlace de descarga con el archivo unificado.</p>
      <form-render
        v-if="isSuperadminEdition || isSuperadminVisualization"
        :form="form"
        :fields="fields"
        ref="formRenderCovaerages"
        containerButtonsClass="col-sm-4 mt-2"
        class="form-modal-prices-or-costs"
      >
      </form-render>
    </b-modal>
</template>
<script>

export default {
  name: 'ModalPricesOrCosts',
  props: ['changePricesOrCosts'],
  data() {
    return {
      keyModalPricesOrCosts: 0,
      form: {},
      fields: [],
      isSuperadminEdition: false,
      isSuperadminVisualization: false
    }
  },
  mounted () {
    this.isSuperadminEdition = !!this.$session.get('cas_user')?.permissions_v2['superadmin-edition']
    this.isSuperadminVisualization = !!this.$session.get('cas_user')?.permissions_v2['superadmin-visualization']
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        {
          fieldType: 'FieldRadio',
          align: 'h',
          name: 'select',
          label: 'Selecione la opción que desea descargar',
          containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info',
          options: [{ id: 'prices', text: 'Precios'}, { id: 'costs', text: 'Costos'}],
          validation: 'required'
        }
      ]
      this.form.select = { id: 'prices', text: 'Precio' }
      this.keyModalPricesOrCosts++
    },
    onAccept() {
      this.$emit('changePricesOrCosts', this.form.select.id === 'prices' ? 'prices' : 'costs')
      this.$bvModal.hide('ModalPricesOrCosts')
    }
  }
}
</script>
<style scoped>
  .form-modal-prices-or-costs {
    text-align: center  ;
  }
</style>