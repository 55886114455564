<template>
  <div>
    <b-card class="quoterCard">
      <div v-for="(container, index) of containers" class="container-quoter-rows" :key="`container${index}`">
        <div class="container-quoter-number">{{ index + 1 }}</div>
        <form-render
          :ref="`formRenderQuoter${index}`"
          :key="keyForm[container.keyFormRender]"
          class="fieldQuoter"
          :fields="container.field"
          :form.sync="form"
        >
        <template #destinationTags>
            <p>{{ keyForm[container.keyFormRender] }}</p>
            <div>
              <b-badge class="badge__cnt" v-for="(data, index) in valueTags" :key="`${index}_valueTags`">{{data.text}}
                <b-button variant="outline" class="border-0" size="sm" @click="removeTagDestination(index)">
                  <feather-icon icon="XIcon" size="20"  class="warning color_icon"/>
                </b-button>
              </b-badge>
            </div>
          </template>
        </form-render>
      </div>
      <div v-if="form.country" class="container-quoter-rows" :key="'container5'">
        <div class="container-quoter-number number-top">5</div>
        <div class="w-100 row">
          <div class="d-flex col-12 col-md-4 pr-0">
            <div class="d-flex pl-1 pr-0 py-1 w-100" v-for="(estimatedValue, index) of estimatedValues" :key="`estimatedValue${index}`">
              <div class="container-img"><img :src="estimatedValue.img" class="weightComponent"/></div>
              <form-render
                :ref="`estimatedValue${index}`"
                :key="estimatedValue.keyFormRender"
                class="fieldQuoter pl-1"
                :fields="estimatedValue.field"
                :form.sync="form"
              />
            </div>
          </div>
          <div v-if="form.country.id === 3" class="col-12 col-md-4">
            <form-render
              ref="formRenderDeclaredValue"
              :key="keyFormRenderDeclaredValue"
              class="fieldQuoter"
              :fields="fieldDeclaredValue"
              :form.sync="form"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="container-quoter-rows" :key="'container5'">
          <div class="container-quoter-number">5</div>
          <div class="w-100 row">
          <div class="d-flex col-12 col-md-4 pr-0">
            <div class="d-flex pl-1 pr-0 py-1 w-100" v-for="(estimatedValue, index) of estimatedValues" :key="`estimatedValue${index}`">
              <div class="container-img"><img :src="estimatedValue.img" class="weightComponent"/></div>
              <form-render
                :ref="`estimatedValue${index}`"
                :key="estimatedValue.keyFormRender"
                class="fieldQuoter pl-1"
                :fields="estimatedValue.field"
                :form.sync="form"
              />
            </div>
          </div>
        </div>
        </div>
      </div>
      <b-button variant="warning" class="quoterButton" @click="validateForm" :disabled="loading.callingService">
        <span v-if="!loading.callingService" class="mb-1">Comparar</span>
        <span class="mt-1 p-2" v-if="loading.callingService"><b-spinner small  /> </span>
      </b-button>
    </b-card>
    <div v-if="loading.firstCall" id="ComparatorTable">
      <b-tabs class="tab-primary" v-model="tabIndex" :key="keyTabsComparator">
        <b-tab title="Por precio">
          <div class="p-1"><div class="msj-info"><li>
              La sección por precio, muestra las opciones más económicas de Envíame, para el origen y destinos seleccionados.
          </li></div></div>
          <comparator-list :loading="loading" :params="params" :queryParams="queryParams" id="ComparatorList" :data="dataRow" :data_2="dataRow_2" :select_destinations="selectDestinations" @sendForm="sendForm"></comparator-list>
        </b-tab>
        <b-tab title="Por cobertura">
          <div class="p-1"><div class="msj-info"><ul>
              <li>La sección por cobertura, muestra los couriers con mayor cobertura en el origen y destinos seleccionados.</li>
              <li>Para este resultado, se indican las opciones más económicas en verde.</li>
          </ul></div></div>
          <comparator-list :loading="loading" :params="params" :queryParams="queryParams" id="ComparatorList" :data="dataRow" :data_2="dataRow_2" :select_destinations="selectDestinations" @sendForm="sendForm"></comparator-list>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import weightIcon from '@/assets/images/icons/quoterIcons/Peso.png'
import BaseServices from '@/store/services/index'
import ComparatorList from './components-comparator/ComparatorList.vue'

export default {
  components: { ComparatorList },
  data () {
    return {
      baseService: new BaseServices(this),
      keyFormRenderDeclaredValue: 0,
      containers: [{field: []}],
      form: {},
      fieldObj: {
        stepOne: [],
        stepTwo: [],
        stepThree: [],
        stepFour:[]
      },
      loading: {
        callingService: false,
        allcarrier: false,
        firstCall: false
      },
      keyForm: { 3: 0 },
      fieldDeclaredValue: [],
      estimatedValues: [],
      valueTags: [],
      dataRow: [],
      dataRow_2: [],
      selectDestinations: [],
      currencySimbol: {
        CL: '$',
        CO: '$',
        PE: 'S/',
        MX: '$'
      },
      tabIndex: 0,
      tabs: ['Por precio', 'Por cobertura'],
      keyTabsComparator: 0,
      params: {},
      queryParams: {}
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesQuoter'
    })
  },
  watch: {
    countries() {
      this.setCountries()
      if (!(['admin', 'superadmin'].includes(this.$session.get('cas_user').role))) {
        const indexCountry = this.fieldObj.stepOne.findIndex(el => el.name === 'country')
        this.fieldObj.stepOne[indexCountry].disabled = true
        this.fieldObj.stepOne[indexCountry].clearable = false
        this.fieldObj.stepOne[indexCountry].containerClass = 'col-sm-12 col-md-8 col-lg-8'
        this.form.country = this.$session.get('cas_user').country
        this.setFieldsProperties(this.containers[0].field, 'country', 'disabled', true)
        this.changeCountry('country', this.form.country)
      }
    },
    tabIndex() {
      this.sendForm()
      this.keyTabsComparator++
    },
    generalLoading: {
      handler () {
        this.loading.countries = !!this.generalLoading.getCountriesQuoter
      }
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fieldObj.stepOne = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', disabled: false, label: 'País', validation: 'required', containerClass: 'col-sm-12 col-md-8 col-lg-8 fieldSelect', change: this.changeCountry, addFlags: true, sort: true }
      ]
      this.fieldObj.stepTwo = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'origin', label: 'Origen', validation: 'required', containerClass: 'col-sm-12 col-md-8 col-lg-8', clearable: true, dependency: 'country', searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600, persistSearch: true }, sort: true}
      ]
      this.fieldObj.stepThree = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'destination', label: 'Destino', validation: 'required', disabled: false, containerClass: 'col-sm-12 col-md-8 col-lg-8', clearAfterSelect: true, dependency: 'country', change: this.changeDestination, placeholder: 'Ingrese hasta 5 destinos', clearable: true, searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600 }, sort: true},
        { name: 'destinationTags', useSlot: true, containerClass: 'col-12' }
      ]
      this.fieldObj.stepFour = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'classification', label: 'Clasificación servicio', validation: 'required', containerClass: 'col-sm-12 col-md-8 col-lg-8', dependency: 'country', clearable: true, sort: true }
      ]
      this.fieldObj.weightValue = [
        { fieldType: 'FieldInput', name: 'weightValue', label: 'Peso promedio de los envíos.', type: 'number', validation: 'required', containerClass: 'col-12', append: {text: 'Kgs', color: '#BBBBBB'}, dependency: 'country', clearable: true }
      ]
      this.fieldDeclaredValue = [
        { fieldType: 'FieldInput', name: 'declaredValue', label: 'Valor declarado', type: 'number', validation: 'required', containerClass: 'col-12 pt-1 pl-2', dependency: 'country', clearable: true, prepend: {} }
      ]
      this.containers = [
        {field: this.fieldObj.stepOne, keyFormRender: 0},
        {field: this.fieldObj.stepTwo, keyFormRender: 1},
        {field: this.fieldObj.stepThree, keyFormRender: 2},
        {field: this.fieldObj.stepFour, keyFormRender: 3}
      ]
      this.estimatedValues = [
        {field: this.fieldObj.weightValue, keyFormRender: 0, img: weightIcon}
      ]
      this.$store.dispatch('fetchService', { name: 'getCountriesQuoter' })
    },
    setCountries() {
      this.fieldObj.stepOne[0].options = this.countries.rows
      this.setSkeletonField('stepOne', false)
    },
    getServicesTypes () {
      const params = {
        country_id: this.form.country.id
      }
      this.baseService.callService('getServiceTypesQuoter', null, params)
        .then(resp => {
          // Se oculta el servicio express ya que no tenemos un diseño para mostrarlo, por ser tarifas por km
          const typeServices = resp.data.map(obj => ({ ...obj, text: obj.name }))
          this.fieldObj.stepFour[0].options = typeServices.filter(el => el.type !== 'express')
          this.setSkeletonField('stepFour', false)
          this.keyForm[3]++
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {
            code: err.message
          }))
        })
    },
    setSkeletonField (name, bool) {
      this.fieldObj[name][0].useSlot = bool
      this.fieldObj[name][0].useSkeleton = bool
      this.fieldObj[name][0].useLabel = bool
    },
    setPlaces(value) {
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {
        search: value
      }
      return this.baseService.callService('getPlacesQuoter', queryParams, params)
        .then(resp => {
          const response = resp.data.places.map(el => ({...el, text: el.full_name, valid: true}))
          return response
        })
        .catch(err => {
          return err
        })
    },
    changeDestination(name, value) {
      //No permite destinos repetidos ni vacios
      this.setSkeletonField('stepThree', true)
      if (this.valueTags.some(ele => ele?.id === value?.id) || !value) {
        this.form[name] = undefined
        this.resetField('stepThree')
        return
      }
      this.setSkeletonField('stepThree', true)
      this.valueTags.push(value)
      this.checkValidation()
      this.form[name] = undefined
      this.resetField('stepThree')
    },
    resetField(name) {
      setTimeout(() => {
        this.setSkeletonField(name, false)
      }, 100)
    },
    checkValidation () {
      // Eliminar o agregar el valor requerido en el input de destino para que al momento de enviar el formulario no interrumpa el proceso debido a como se muestra en la UI los tags de destinos, llos valores se toman del fieldSelect pero se agregan a un array "valueTags"
      if (this.valueTags.length >= 1 && this.containers[2].field[0].validation === 'required') this.setFieldsProperties(this.containers[2].field, 'destination', 'validation', '')
      if (this.valueTags.length === 0 && this.containers[2].field[0].validation === '') this.setFieldsProperties(this.containers[2].field, 'destination', 'validation', 'required')

      //No permite agregar mas de 7 destinos
      if (this.valueTags.length >= 5) {
        this.setFieldsProperties(this.containers[2].field, 'destination', 'disabled', true)
        this.setFieldsProperties(this.containers[2].field, 'destination', 'placeholder', 'Ingresaste el máximo de 5 destinos')
        this.setFieldsProperties(this.containers[2].field, 'destination', 'containerClass', 'col-sm-12 col-md-8 col-lg-8')
      } else if (this.containers[2].field[0].disabled) {
        this.setFieldsProperties(this.containers[2].field, 'destination', 'disabled', false)
        this.setFieldsProperties(this.containers[2].field, 'destination', 'containerClass', 'col-sm-12 col-md-8 col-lg-8 fieldSelect')
        this.setFieldsProperties(this.containers[2].field, 'destination', 'placeholder', 'Ingrese hasta 5 destinos')
      }
    },
    changeCountry(name, value) {
      this.refreshElements(true)
      this.clearForm()
      if (this.valueTags.length !== 0) this.valueTags = []
      if (value) {
        this.fieldObj.stepTwo[0].containerClass = 'col-sm-12 col-md-8 col-lg-8 fieldSelect'
        this.fieldObj.stepThree[0].containerClass = 'col-sm-12 col-md-8 col-lg-8 fieldSelect'
        this.fieldObj.stepFour[0].containerClass = 'col-sm-12 col-md-8 col-lg-8 fieldSelect'
        this.fieldDeclaredValue[0].prepend.text = this.currencySimbol[value.code]
        this.getServicesTypes()
        this.refreshElements(false, 'stepFour')
        this.checkValidation()
        
      } else {
        Object.keys(this.form).forEach(el => { if (el !== 'country') this.form[el] = undefined })
        this.fieldObj.stepTwo[0].containerClass = 'col-sm-12 col-md-8 col-lg-8'
        this.fieldObj.stepThree[0].containerClass = 'col-sm-12 col-md-8 col-lg-8'
        this.fieldObj.stepFour[0].containerClass = 'col-sm-12 col-md-8 col-lg-8'
        this.fieldDeclaredValue[0].prepend = {}
        this.refreshElements(false)
      }

    },
    async validateForm () {
      if (!Number.isInteger(parseFloat(this.form.weightValue))) {
        await this.$refs.estimatedValue0[0].$refs.observer.setErrors({'Peso promedio de los envíos.': ['Por favor ingresa un numero entero']})
        this.setSkeletonField('weightValue', false)
      } else {
        this.form.weightValue = parseInt(this.form.weightValue)
        const value1 = await this.$refs.formRenderQuoter0[0].onlyCheckForm()
        const value2 = await this.$refs.formRenderQuoter1[0].onlyCheckForm()
        const value3 = await this.$refs.formRenderQuoter2[0].onlyCheckForm()
        const value4 = await this.$refs.formRenderQuoter3[0].onlyCheckForm()
        const value5 = await this.$refs.estimatedValue0[0].onlyCheckForm()
        const allValues = [value1 && value2 && value3 && value4 && value5]
        if (this.form.country.id === 3) allValues.push(await this.$refs.formRenderDeclaredValue?.onlyCheckForm())
        const isCheck = allValues.every(el => el === true)
        if (isCheck) this.sendForm()
      }
    },
    refreshElements(value, omit = null) {
      setTimeout(() => {
        Object.keys(this.fieldObj).map(key => {
          if (key !== omit || key !== 'stepOne') this.setSkeletonField(key, value)
        })
      }, 200)
    },
    clearForm () {
      this.form = {
        ...this.form,
        destination: undefined,
        origin: undefined,
        classification: undefined,
        weightValue: undefined,
        declaredValue: undefined
      }
    },
    sendForm(showSecondTable) {
      let table = null
      let nameService = ''
      if (!showSecondTable && this.tabs[this.tabIndex] === 'Por precio') nameService = 'getComparePricingQuoterForPrices'
      if (!showSecondTable && this.tabs[this.tabIndex] === 'Por cobertura') nameService = 'getComparePricingQuoter'
      if (showSecondTable) {
        nameService = 'getAllCouriersQuoterForPrices'
        this.loading.allcarrier = true
      } else {
        this.loading.callingService = true
      }
      if (!this.loading.firstCall) this.loading.firstCall = true
      const params = {
        country_id: this.form.country.id,
        from_place_id: this.form.origin.id,
        service_type_id: this.form.classification.id,
        origin: this.form.origin.name,
        category: this.form.classification.name
      }
      const queryParams = {
        weight : this.form.weightValue,
        declared_value: this.form.declaredValue || ''
      }
      //agregar los places ids
      this.valueTags.forEach(({id}, i) => queryParams[`to_place_ids[${i}]`] = id)
      this.params = { ...params }
      this.queryParams = { ...queryParams }
      //excluir los carrier ya se devolvieron
      if (showSecondTable) showSecondTable.forEach((el, i) => queryParams[`exclude_carrier_ids[${i}]`] = el)
      this.selectDestinations = this.valueTags
      this.selectDestinations.map(el => el.minVal = Infinity)
      this.baseService.callService(nameService, queryParams, params)
        .then(resp => {
          if (showSecondTable) {
            this.dataRow_2 = resp
            table = document.getElementById('ComparatorListShowMore')
          } else {
            this.dataRow = resp
            table = document.getElementById('ComparatorList')
          }
          setTimeout(() => {
            if (table) table.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }, 100)
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {
            code: err
          }))
        })
        .finally(() => {
          this.loading.callingService = false
          this.loading.allcarrier = false
        })
    },
    setFieldsProperties (fields, name, propertyField, value) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index][propertyField] = value
      }
    },
    removeTagDestination (index) {
      if (this.loading.callingService) return
      if (this.loading.allcarrier) return
      this.valueTags.splice(index, 1)
      this.checkValidation()
    }
  }
}
</script>

<style lang="scss">
.badge__cnt{
  margin: 5px;
  background: #58bbe4;
  padding: 2px 5px;
}
.main-box-autocomplete {
  z-index: 99 !important;
  > .element-box-autocomplete{
    &:hover {
      white-space: normal !important;
    }
  }
}
.color_icon{ //color para el icono X de los tags
  stroke: white;
}
</style>