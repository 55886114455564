<template>
  <div class="table-quoter" >
    <b-tabs v-model="dataList.tabIndex" :align="'center'" class="tab-primary pt-4" justified @input="changeServiceType">
      <b-tab v-for="(services, index) in dataList.servicesTypes" :key="index">
        <template #title>
          <div class="text-center">
            <span v-html="services.name"></span>
          </div>
        </template>
      </b-tab>
    </b-tabs>
    <div v-if="!dataList.form.lengthValue || !dataList.form.heightValue || !dataList.form.widthValue" justified>
      <b-alert variant="warning" show>
        <div class="alert-body d-flex align-items-center">
          <feather-icon class="mr-25" icon="AlertTriangleIcon" />
          <ul class="mb-0">
            <li v-if="dataList.servicesTypes[dataList.tabIndex] === 'express' && dataList.form.countryOfDestination.code.toLowerCase() !== 'us'">
              El <span class="font-weight-bolder">servicio Express</span> establece parámetros de cobertura por KM (el destino indicado no será considerado)
            </li>
            <li v-if="(!dataList.form.lengthValue || !dataList.form.heightValue || !dataList.form.widthValue) && dataList.form.countryOfDestination.code.toLowerCase() !== 'us'">
              Recomendamos <span class="font-weight-bolder">indicar las dimensiones del envío,</span> ya que pueden ocasionar cambios en las tarifas
              efectivas
            </li>
            <li>{{`TARIFAS NO INCLUYEN ${dataList.form.countryOfOrigin.code.toLowerCase() === 'pe' ? 'IGV' : dataList.form.countryOfDestination.code.toLowerCase() === 'us' ? 'IMPUESTOS' : 'IVA'}`}}</li>
          </ul>
        </div>
      </b-alert>
    </div>
    <b-table 
      responsive="sm" 
      :key="keyTableRender" 
      :items="sortedItems"
      :fields="schema"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      no-local-sorting
      show-empty
      borderless= "borderless"
      stickyColumn="false"
      :stickyHeader="`calc(100vh - 2.5rem)`"
    >
      <template #empty="">
        <span class="text-center w-full push-left">{{ 'No se encontraron registros para mostrar' }}</span>
      </template>
      <template #cell(carrier)="scope">
        <div class="container-img-tablequoter"><img class="logo-table-quoter" :alt="scope.item.carrier" :src="`https://storage.googleapis.com/enviame-misc/carriers/logos/color/${scope.item.carrier.toLowerCase()}.png`"/></div>
      </template>
      <template #head()="data">
        <span :class="{'cursor-pointer' : true }">
          <span v-if="!data.field.onlyIcon">{{ !data.field.noTranslate ? $t(data.field.label) : data.field.label }}</span>
          <feather-icon v-if="data.field.icon && !data.field.onlyIcon" :icon="data.field.icon" v-b-tooltip.top :title="!data.field.noTranslate ? $t(data.field.text) : data.field.text"/>
          <feather-icon v-if="data.field.icon && data.field.onlyIcon" :icon="data.field.icon" v-b-tooltip.top :title="!data.field.noTranslate ? $t(data.field.label || data.field.text) : (data.field.label || data.field.text) "/>
        </span>
        <span>
          <b-button
            v-if="data.field.showPaginationPricingLeft"
            :disabled="dataList.currentKgs.from === 1 || dataList.arrayCurrentKgs[dataList.tabIndex].type === 'doc'"
            @click="calculateNewKg('prev')"
            variant="default"
            v-b-tooltip.hover="dataList.currentKgs.from === 1? '' : `${calculateTooltipKg('prev').from} a ${calculateTooltipKg('prev').to} KILOS`"
            class="p-0"
          >
            <feather-icon icon="ChevronsLeftIcon" size="4x" class="text-white"/>
          </b-button>
          <b-button
            :disabled="dataList.arrayCurrentKgs[dataList.tabIndex].type === 'doc'"
            v-if="data.field.showPaginationPricingRight"
            @click="calculateNewKg('next')"
            variant="default"
            v-b-tooltip.hover="`${calculateTooltipKg('next').from} a ${calculateTooltipKg('next').to} KILOS`"
            class="p-0"
          >
            <feather-icon icon="ChevronsRightIcon" size="4x" class="text-white"/>
          </b-button>
        </span>
      </template>
      <template #cell()="scope">
        <span v-if="scope.item[scope.field.key]">{{scope.item[scope.field.key]}}</span>
        <span v-else-if="scope.field.key !== 'paginationPricingLeft' && scope.field.key !== 'paginationPricingRight'">-</span>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  props: ['dataList', 'generateArrayKgs', 'changeTab'],
  name: 'MultipleWeightsList',
  data () {
    return {
      keyTableRender: 0,
      schema: [],
      rows: [],
      sortBy: 'carrier',
      sortDesc: false
    }
  },
  computed: {
    sortedItems() {
      return this.sortRows(this.sortBy, this.sortDesc, this.rows)
    }
  },
  mounted () {
    this.loadedPrices()
  },
  methods: {
    setSchema (type) {
      this.schema = [
        {label: 'Courier', sortable: true, key: 'carrier', useSlot: true, class: ['text-center']},
        {label: 'Servicio', sortable: true, key: 'service', class: ['text-center']},
        {label: 'Tiempo de entrega', sortable: true, key: 'days', width: '5%', class: ['text-center']},
        {label: '', useSlot: true, key: 'paginationPricingLeft', showPaginationPricingLeft: true, class: ['text-center'] },
        {label: '', useSlot: true, key: 'paginationPricingRight', showPaginationPricingRight: true, class: ['text-center'] }
      ]
      this.isExpress(type)
      this.setAddSchemaForType(type)
      this.keyTableRender++
    },
    setAddSchemaForType(type) {
      const indexPaginationPricingRight = this.schema.findIndex(el => el.key === 'paginationPricingRight')
      if (type !== 'doc') {
        this.dataList.arrayCurrentKgs.sort((a, b) => b - a)
        this.dataList.arrayCurrentKgs.map(el => {
          const current = {sortable: true, key: `${el}${type === 'express' ? 'km' : 'kg'}`, class: ['text-center'], noTranslate: true}
          if (parseFloat(this.dataList.form.weightValue) === el && (this.dataList.form.weightValue > parseFloat(this.dataList.volumetricWeightKgs)) && type !== 'express') {
            current.class.push('thead--backgound')
          } else if (parseFloat(this.dataList.volumetricWeightKgs) === el && (this.dataList.form.weightValue < parseFloat(this.dataList.volumetricWeightKgs)) && type !== 'express') {
            current.class.push('thead--backgound') 
          }
          this.schema.splice(indexPaginationPricingRight, 0, current)
        }) 
      } else {
        this.schema.splice(indexPaginationPricingRight, 0, {sortable: true, key: '0.5kg', class: ['text-center'], noTranslate: true})
      }
    },
    isExpress (type) {
      const indexLimitKm = this.schema.findIndex(el => el.key === 'limitKM')
      if (type === 'express' && indexLimitKm === -1) {
        this.schema.splice(indexLimitKm - 1, 0, {label: 'Límite KM', sortable: true, key: 'limitKM', class: ['text-center']})
      } else if (indexLimitKm !== -1) {
        this.schema.splice(indexLimitKm, 1)
      }
    },
    loadedPrices () {
      this.rows = []
      let currentId = 0
      this.dataList.data.map((rowKg, indexKg) => {
        rowKg.data.map((rowService) => {
          rowService.services.map((detailService) => {
            const typeDoc = this.dataList.servicesTypes[this.dataList.tabIndex].type === 'doc' ? 0.5 : undefined
            const currentKg = typeDoc || this.dataList.arrayCurrentKgs[indexKg]
            const indexRowServiceCarrierNoKg = this.rows.findIndex(row => {
              return row.service_code === detailService.code && row.carrier === rowService.carrier && !row[`${currentKg}kg`] && row[`${currentKg}kg`] !== 0
            })
            if (indexRowServiceCarrierNoKg !== -1) {
              this.rows[indexRowServiceCarrierNoKg][`${currentKg}${this.dataList.servicesTypes[this.dataList.tabIndex].type === 'express' ? 'km' : 'kg'}`] = detailService.price_formatted
            } else {
              const row = {
                id: currentId,
                carrier: rowService.carrier, 
                service_code: detailService.code, 
                service: detailService.name,
                days: `${detailService.transit_days} ${parseInt(detailService.transit_days) === 1 ? 'día' : 'días'}`,
                group_key: detailService.group_by_carrier_service_type ? `${detailService.service_types[0]}_${rowService.carrier}`.toLowerCase() : '',
                limitKM: detailService.km_limit
              }
              row[`${currentKg}${this.dataList.servicesTypes[this.dataList.tabIndex].type === 'express' ? 'km' : 'kg'}`] = detailService.price_formatted
              this.rows.push(row)
              currentId++
            }
          })
        })
      })
      this.setSchema(this.dataList.servicesTypes[this.dataList.tabIndex].type)
    },
    calculateTooltipKg (type) {
      const multiplier = type === 'prev' ? -1 : 1
      if (this.dataList.currentKgs.from <= this.dataList.currentKgs.size && type === 'prev') {
        return {from: 1, to: this.dataList.currentKgs.size}
      }
      return {from: this.dataList.currentKgs.from + (multiplier * this.dataList.currentKgs.size), to: this.dataList.currentKgs.from + (multiplier * this.dataList.currentKgs.size) + this.dataList.currentKgs.size - 1}
    },
    calculateNewKg (type) {
      const multiplier = type === 'prev' ? -1 : 1
      if (this.dataList.currentKgs.from <= this.dataList.currentKgs.size && type === 'prev') {
        const currentKgsfrom = 1
        this.$emit('generateArrayKgs', {from: currentKgsfrom})

      } else {
        const currentKgsfrom = this.dataList.currentKgs.from + (multiplier * this.dataList.currentKgs.size)
        this.$emit('generateArrayKgs', {from: currentKgsfrom})
      }
    },
    sortRows(sortBy, sortDesc, rows) {
      if (sortBy.includes('kg') || sortBy.includes('km') || sortBy.includes('KM')) {
        return rows.slice().sort((a, b) => {
          const aValue = typeof a[sortBy] === 'string' ? parseInt(a[sortBy]?.replaceAll(/[^0-9]/g, '')) : a[sortBy]
          const bValue = typeof a[sortBy] === 'string' ? parseInt(b[sortBy]?.replaceAll(/[^0-9]/g, '')) : b[sortBy]
          return sortDesc ? aValue - bValue : bValue - aValue
        })
      } else {
        return rows.slice().sort((a, b) => {
          return sortDesc ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy])
        })
      }
    },
    changeServiceType(tab) {
      this.$emit('changeTab', tab)
    }
  }
}
</script>
