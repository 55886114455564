<template>
  <div>
    <b-table
      responsive="sm"
      :key="keyTableRender"
      :items="rows.length ? [rows[0].weight_intervals] : []"
      :fields="schema"
      borderless="borderless"
      class="coverageKmTable"
      show-empty
    >
      <template #empty="">
        <span class="text-center w-full push-left">{{ 'No se encontraron registros para mostrar' }}</span>
      </template>
    </b-table>
    <div class="msg-info-coverageKm">
      <feather-icon class="mb-25 icon-color" size="22" icon="InfoIcon" />
      <span> Información destacada </span>
      <ul>
        <li v-if="rows.length && rows[0].km_limit">Límite de KM: {{ rows[0].km_limit }}</li>
        <li>Días de tránsito: Mismo día</li>
        <li class="font-weight-bolder">Cobertura sujeta a condiciones del courier</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['rows'],
  data() {
    return {
      schema: [],
      arrayCurrentKm: [],
      keyTableRender: 0
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    rows: {
      handler() {
        this.setInitialData()
        this.keyTableRender++
      }
    }
  },
  methods: {
    setInitialData() {
      this.schema = [{ label: 'Distancia', key: 'price', class: ['text-left'] }]
      if (this.rows.length) {
        this.arrayCurrentKm = Object.keys(this.rows[0].weight_intervals)
        this.arrayCurrentKm = this.arrayCurrentKm?.sort(function (a, b) {
          return parseFloat(a) - parseFloat(b)
        })
        this.arrayCurrentKm.forEach((el) => {
          this.schema.push({ label: `hasta ${el}km`, key: el, class: ['text-center'] })
        })
      }
    }
  }
}
</script>
<style>
.msg-info-coverageKm {
  border: 1px solid #b9d5f4;
  width: fit-content;
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 20px;
  background: #e6effa;
  margin-top: 1rem;
}
.msg-info-coverageKm > span {
  color: #0169de;
}
.icon-color {
  color: #0169de;
}
.coverageKmTable > table > thead > tr > th:first-child {
  background: #f3f2f7 !important;
  border: 1px solid #ebe9f1 !important;
}
.coverageKmTable > table > tbody > tr > :first-child {
  background: #f3f2f7 !important;
  border: 1px solid #ebe9f1 !important;
}
.coverageKmTable > table > thead > tr > th {
  background: #fafafc !important;
  border: 1px solid #ebe9f1 !important;
}
.coverageKmTable > table > tbody > tr > td {
  border: 1px solid #ebe9f1 !important;
}
</style>
