<template>
    <b-card>
      <div v-if="this.tabs[tabIndex] === 'Tarifas' && carrier.text && carrier.code" class="rate-logo-carrier">
        <img :alt="carrier && carrier.text" :src="`https://storage.googleapis.com/enviame-misc/carriers/logos/color/${carrier.code.toLowerCase()}.png`"/>
      </div>
      <div class="d-flex" v-if="loading.mySession">
        <b-skeleton height="40px" width="20%" class="m-2 spacing-label-field"/>
        <b-skeleton height="40px" width="20%" class="m-2 spacing-label-field"/>
        <b-skeleton height="40px" width="20%" class="m-2 spacing-label-field"/>
      </div>
      <b-tabs align="left" class="tab-primary" v-model="tabIndex" :key="keyTariffCalculator" v-if="!loading.mySession">
        <b-tab title="Tarifas" v-if="isAdmin">
          <tariffs-multiple-couriers @getCarrier="getCarrier" v-if="isCountry"></tariffs-multiple-couriers>
        </b-tab>
        <b-tab title="Tarifas" v-if="!isAdmin">
          <tariffs @getCarrier="getCarrier" v-if="isCountry"></tariffs>
        </b-tab>
        <b-tab title="Cotizador">
          <new-quoter v-if="firstChargeNewCotizador" :data="data"></new-quoter>
        </b-tab>
        <b-tab title="Comparador">
          <comparator v-if="firstChargeComparator"></comparator>
        </b-tab>
        <b-tab title="Simulator" v-if="isAdmin">
          <template #title>
            Simulador
            <b-badge class="ml-05" variant="warning">BETA</b-badge>
          </template>
          <simulator v-if="firstChargeSimulator"></simulator>
        </b-tab>
      </b-tabs>
    </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import tariffs from './tariffs/tariffs.vue'
import newQuoter from './quoter/NewQuoter.vue'
import comparator from './comparator/Comparator.vue'
import tariffsMultipleCouriers from './tariffs/TariffsMultipleCouriers.vue'
import simulator from './simulator/Simulator.vue'

export default {
  components: { tariffs, newQuoter, comparator, tariffsMultipleCouriers, simulator },
  data () {
    return {
      keyTariffCalculator: 0,
      form: {},
      tabIndex: 0,
      carrier: {},
      tabs: ['Tarifas', 'Cotizador', 'Comparador', 'Simulator'],
      isCountry: null,
      loading: {
        mySession: true
      },
      firstChargeNewCotizador: false,
      firstChargeComparator: false,
      firstChargeSimulator: false,
      isAdmin: false,
      data: {}
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      countries: 'getCountriesQuoter',
      allCountries: 'getCountriesOriginsModal'
    })
  },
  watch: {
    mySession () {
      if (this.loading.mySession) {
        this.setInitialData()
      }
    },
    tabIndex () {
      if (this.tabs[this.tabIndex] === 'Cotizador') this.firstChargeNewCotizador = true
      if (this.tabs[this.tabIndex] === 'Comparador') this.firstChargeComparator = true
      if (this.tabs[this.tabIndex] === 'Simulator') this.firstChargeSimulator = true
    },
    countries() {
      this.data.countries = this.countries.rows
    },
    allCountries() {
      this.data.allCountries = this.allCountries.rows
    }
  },
  mounted() {
    this.$store.dispatch('fetchService', { name: 'getCountriesQuoter' })
    this.$store.dispatch('fetchService', { name: 'getCountriesOriginsModal' })
    if (this.loading.mySession && this.$session.get('cas_user')) {
      this.setInitialData()
    }
  },
  methods: {
    getCarrier(carrier) {
      this.carrier = carrier
    },
    setInitialData() {
      this.isAdmin = this.$session.get('cas_user').role === 'admin' || this.$session.get('cas_user').role === 'superAdmin'
      this.isCountry = this.$session.get('cas_user')?.country
      this.loading.mySession = false
      this.keyTariffCalculator++
    }
  }
}
</script>