<template>
  <div>
    <b-modal 
      centered 
      id="ModaltariffsHistory" 
      hide-footer 
      size="lg"
      modal-class="custom-dialog dialog-900"
      title="Administrar versiones"
    >
      <b-table
        :items="rows"
        :fields="schema"
        :select-mode="'single'"
        responsive="sm"
        ref="selectableRatesTable1"
        selectable
        selected-variant="primary"
        @row-selected="onRowSelected"
      >
      </b-table>
    </b-modal>
  </div>
</template>
<script>

export default {
  name: 'ModaltariffsHistory',
  props: ['rates', 'changeRateId', 'rateId'],
  data() {
    return {
      formRenderModalEditCoverages: 0,
      schema: [],
      rows: [],
      isCurrent: true,
      date: '',
      sortRates: [],
      ratesTable: 0,
      selected: [],
      selectedRateId: ''
    }
  },
  watch: {
    rates() {
      this.setRates()
      this.setInitialData()
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        {label: 'Número', key: 'number'},
        {label: 'Fecha modificación', key: 'date'},
        {label: 'Rango vigencia', key: 'range'},
        {label: '', key: 'status'}
      ]
    },
    setRates() {
      let allRates = [...this.rates]
      allRates = allRates.sort((a, b) => new Date(b.validity_date).getTime() - new Date(a.validity_date).getTime())
      this.rows = allRates.map((el, index) => {
        const formatValidityDate =  `${this.$options.filters.moment(el.validity_date, 'DD/MM/YYYY')}`
        let rowVariantRate = 'none'
        let ranges = this.$options.filters.moment(el.validity_date, 'DD/MM/YYYY')
        let statusRate = ''
        if (this.rateId === el.id) rowVariantRate = 'primary'
        if (el.type === 'current') statusRate = 'Versión actual'
        if (el.type === 'future') statusRate = 'Programada'
        if (el.type === 'expired') statusRate = 'Expirada'
        if (index >= 1) ranges = `${this.$options.filters.moment(el.validity_date, 'DD/MM/YYYY')} - ${this.$options.filters.moment(allRates[index - 1]?.validity_date, 'DD/MM/YYYY')}`
        return {
          ...el,
          id: el.id,
          date: formatValidityDate,
          validity_date: el.validity_date,
          number: (allRates.length - index).toFixed(1),
          user: el.last_editor_email,
          range: ranges,
          status: statusRate,
          _rowVariant: rowVariantRate,
          rate_customization_type: el.rate_customization_type
        }
      })
    },
    onRowSelected(item) {
      if (this.rateId !== item[0].id) {
        this.selected = item
        this.rows.forEach(el => el._rowVariant = 'none')
        this.$emit('changeRateId', item[0])
      }
      this.$bvModal.hide('ModaltariffsHistory')
    }
  }
}
</script>