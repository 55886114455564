<template>
  <div>
    <filter-swapper id="tariffs" :buttons="buttons" :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render
          :key="keyFormRender"
          :fields="fields"
          :form.sync="form"
          @send="buttonFind"
          class="px-0"
          containerButtonsClass="col-sm-12 col-md-12 col-lg-3 mt-2 mb-4 buttons-rates-costs"
        >
          <template #buttons>
            <b-button variant="warning" :disabled="buttonSend.disabled || loading.prices || loading.rates" @click="buttonFind">
              <feather-icon v-if="!loading.prices && !loading.rates" icon="SearchIcon" />
              <span v-if="loading.prices || loading.rates"><b-spinner small /> </span>
            </b-button>
            <b-button
              variant="outline-light"
              v-b-tooltip.hover
              title="Limpiar filtros"
              class="ml-2"
              @click="cleanFilter"
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
          </template>
        </form-render>
        <div>
          <div class="msj-info" v-if="showTable">
            <li>
              Para obtener toda la información de las tarifas de un lugar de origen, puede descargarla presionando el
              botón "Exportar servicio {{ serviceSelected.name }}"
            </li>
          </div>
        </div>
        <b-form-group class="mt-2 mb-2 form-group-tariffs" v-if="showTable">
          <b-form-radio-group id="rates-costs-radio" @change="changeRatesCostsRadio" v-model="ratesCostsRadio" :options="ratesCostsRadioOptions" />
          <span
            :title="$t(buttonSend.disabled ? 'Debe completar el formulario' : (rows.length ? 'Descargar archivo' : 'No hay datos para descargar'))"
            v-b-tooltip.bottom.hover
            class="text-right button-with-alert"
          >
            <b-button
              variant="success"
              @click="exportExcel"
              class="ml-2"
              :disabled="!showTable || loading.donwLoadExcel || rows.length === 0"
              v-if="showTable"
            >
              <feather-icon v-if="!loading.donwLoadExcel" icon="DownloadIcon" />
              <b-spinner v-if="loading.donwLoadExcel" small />
              <span class="ml-1">
                Exportar servicio {{ serviceSelected.name }}
              </span>
            </b-button>
          </span>
        </b-form-group>
        <ul v-if="showTable && form.categoryService && !isExpress" class="navbar-nav mb-1">
          <li>
            <div class="w-30 float-right">
              <b-input-group>
                <b-form-input
                  class="border-secondary"
                  v-model="seekerValue"
                  placeholder="ingrese el nombre del Destino"
                  cleareable
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    v-b-tooltip.hover
                    title="Buscar"
                    @click="searchSpecifically"
                    :disabled="seekerValue.length < 3"
                  >
                    <feather-icon icon="SearchIcon"
                  /></b-button>
                  <b-button variant="outline-secondary" v-b-tooltip.hover title="Limpiar" @click="cleanSearch">
                    <feather-icon icon="XIcon"
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
              <span
                v-if="seekerValue.length < 3 && seekerValue.length > 0"
                class="text-danger position-absolute bg-white"
                >{{ $t('msg-nchars', { key: 3 }) }}
              </span>
            </div>
          </li>
        </ul>
        <div class="d-flex" v-if="showTable">
          <b-tabs align="left" class="tab-primary" v-model="tabIndex">
            <b-tab v-for="(services, index) in serviceTypes" :key="index">
              <template #title>
                <div class="text-center">
                  <span v-html="services.name"></span>
                </div>
              </template>
            </b-tab>
          </b-tabs>
          <a class="ml-auto text-primary mt-1 font-weight-bold" @click="openModal">
            <span class="icon-class-tariff"><feather-icon icon="ClockIcon" class="iconClass" size="16" /></span>
            <span>Historial de tarifas</span>
          </a>
        </div>
      </template>
    </filter-swapper>
    <table-render
      v-if="
        showTable &&
        !loading.prices &&
        form.categoryService &&
        !isExpress
      "
      :schema.sync="schema"
      :rows="rows"
      :stickyHeader="`calc(100vh - 2.5rem)`"
      id="rates-costs-table"
      :tableClass="'with-scroll'"
    >
    </table-render>
    <pagination
      v-if="
        showTable &&
        !loading.prices &&
        form.categoryService &&
        !isExpress &&
        form.country.code !== 'MX'
      "
      :pagination="pagination"
      :showSize="true"
    />
    <CoveragesKmList
      :rows="rows"
      v-if="showTable && !loading.prices && form.categoryService && !!isExpress"
    ></CoveragesKmList>
    <div class="table-render-skeleton" v-if="loading.prices">
      <b-skeleton-table :rows="15" :columns="schema.length || 10" />
    </div>
    <ModaltariffsHistory
      :country="countrySelected"
      @changeRateId="changeRateId"
      :rateId="rateId"
      :rates="filterRates"
      :carrier="carrierSelected"
      :service="serviceSelected"
      :form="form"
    ></ModaltariffsHistory>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModaltariffsHistory from './ModalManageVersions.vue'
import CoveragesKmList from './components/CoveragesKmList.vue'
import BaseServices from '@/store/services/index'

export default {
  name: 'tariffs',
  props: ['getCarrier'],
  components: { ModaltariffsHistory, CoveragesKmList },
  data() {
    return {
      rows: [],
      schema: [],
      buttons: [],
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      keyFormRender: 0,
      fields: [],
      form: {},
      buttonSend: {
        icon: 'SearchIcon',
        title: 'Buscar',
        color: 'warning',
        disabled: true
      },
      fieldSkipLine: {
        name: 'line',
        useSkeleton: true,
        skipLine: true,
        containerClass: 'col-12'
      },
      showTable: false,
      serviceTypes: [],
      rateServiceId: '',
      tabIndex: 0,
      filterRates: [],
      ratesCostsRadio: 'Tarifas',
      ratesCostsRadioOptions: ['Tarifas'],
      serviceIndex: 0,
      pricesOrCosts: 'prices',
      carrierSelected: null,
      serviceSelected: null,
      countrySelected: null,
      rateId: null,
      baseService: new BaseServices(this),
      seekerValue: '',
      arrayCurrentKgs: [],
      isExpress: false,
      loading: {
        rates: true,
        countries: true,
        prices: true,
        donwLoadExcel: false
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      paramPage: 1,
      paramPageSize: 20

    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesRatesQuoter',
      places: 'getPlacesRatesQuoter',
      carriers: 'getCarriersRatesQuoter',
      categoryService: 'getCategoryServiceRatesQuoter',
      services: 'getServiceRatesQuoter',
      rates: 'getRatesRatesQuoter',
      prices: 'getPricesRatesQuoter',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    countries() {
      this.setCountries()
      if (!(['admin', 'superadmin'].includes(this.$session.get('cas_user').role))) {
        const indexCountry = this.fields.findIndex((el) => el.name === 'country')
        this.fields[indexCountry].disabled = true
        this.fields[indexCountry].clearable = false
        this.form.country = this.$session.get('cas_user').country
      }
    },
    carriers() {
      this.setCarriers()
    },
    categoryService() {
      this.setCategoryService()
    },
    services() {
      this.setServiceRatesQuoter()
    },
    rates() {
      const existRateDifferential = this.rates.rows.some((el) => el.rate_customization_type === 'differential')
      if (existRateDifferential && !['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        this.ratesCostsRadioOptions = ['Tarifas Generales', 'Tarifas Preferentes']
      } else {
        this.ratesCostsRadioOptions = ['Tarifas']
      }
      this.ratesCostsRadio = this.ratesCostsRadioOptions[0]
      this.setRatesRatesQuoter()
    },
    prices() {
      this.setPricesRatesQuoter()
    },
    generalLoading: {
      handler() {
        this.loading.rates = false
        this.loading.countries = !!this.generalLoading.getCountriesRatesQuoter
        this.loading.prices = !!this.generalLoading.getPricesRatesQuoter
        this.loading.donwLoadExcel = !!this.generalLoading.getFileRatesQuoter
      }
    },
    tabIndex(val) {
      this.serviceIndex = val
      this.serviceSelected = this.services.rows[val]
      const params = {
        carrier_id: this.form.carriers.id,
        service_id: this.services.rows[val]?.id
      }
      const queryParams = {}
      if (this.form.idSeller) queryParams.organization_id = this.form.idSeller
      this.getServices('getRatesRatesQuoter', params, queryParams)
    },
    'pagination.page'() {
      this.paramPage = this.pagination.page
      this.getCoverages()
    },
    'pagination.limit'() {
      this.paramPageSize = this.pagination.limit
      this.getCoverages()
    },
    rateId(val) {
      const rate = this.filterRates.filter((el) => val === el.id)
      rate[0]?.rate_type === 'km' ? (this.isExpress = true) : (this.isExpress = false)
    }
  },
  mounted() {
    this.setInitialData()
    if (['marketplace', 'ecommerce'].includes(this.$session.get('cas_user').role)) {
      this.fields.splice(1, 0, this.fieldSkipLine)
      this.fields.splice(3, 2)
    }
    // else {
    // this.ratesCostsRadioOptions = ['Tarifas Generales', 'Tarifas Preferentes']
    // }
  },
  methods: {
    setInitialData() {
      this.fields = [
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'country',
          disabled: false,
          label: 'País',
          validation: 'required',
          containerClass: 'col-12 col-md-4 col-lg-3 container-info',
          change: this.changeCountry,
          addFlags: true,
          sort: true
        },
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'places',
          label: 'Origen',
          validation: 'required',
          containerClass: 'col-12 col-md-4 col-lg-3 container-info',
          change: this.changePlaces,
          dependency: 'country',
          clearable: true,
          searchOnType: {
            fx: this.setPlaces,
            nChars: 3,
            debounce: 600,
            allowSearchAsValue: false,
            persistSearch: true
          }
        },
        this.fieldSkipLine,
        {
          fieldType: 'FieldInput',
          type: 'text',
          name: 'idSeller',
          label: 'Id Usuario Tarifa Diferencial',
          containerClass: 'col-12 col-md-4 col-lg-3',
          dependency: 'places',
          inputDisabledClass: 'is-disabled',
          change: this.changeIdSeller,
          changeDebounce: 600
        },
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'carriers',
          label: 'Courier',
          validation: 'required',
          containerClass: 'col-12 col-md-4 col-lg-3 container-info',
          change: this.changeCarriers,
          dependency: 'places',
          sort: true
        },
        {
          fieldType: 'FieldSelect',
          useSkeleton: true,
          useLabel: true,
          name: 'categoryService',
          label: 'Categoría de Servicio',
          validation: 'required',
          containerClass: 'col-12 col-md-4 col-lg-3 container-info',
          change: this.changeCategoryService,
          dependency: 'carriers',
          sort: true
        }
      ]
      this.schema = []
      this.getServices('getCountriesRatesQuoter', null)
    },
    getCoverages() {
      this.serviceSelected = this.services.rows[this.serviceIndex]
      if (this.ratesCostsRadio === 'Tarifas' || this.ratesCostsRadio === 'Tarifas Generales' || this.ratesCostsRadio === 'Tarifas Preferentes') this.getPrices()
    },
    getPrices() {
      this.pricesOrCosts = 'prices'
      const params = this.getParams()
      if (this.filterRates.length && !this.form.idSeller) {
        this.getServices('getPricesRatesQuoter', params.params, params.queryParams)
      } else if (this.filterRates.length && this.form.idSeller) {
        this.getServices('getPricesRatesQuoter', params.params, params.queryParams)
      }
    },
    getParams() {
      const params = {
        carrier_id: this.form.carriers.id,
        service_id: this.services.rows[this.serviceIndex]?.id,
        rate_id: this.rateId,
        place_id: this.form.places.id
      }
      const rate = this.filterRates.filter((el) => this.rateId === el.id)
      const queryParams = {
        weightIntervalsLimit: rate[0]?.rate_type === 'km' ? 10 : 6,
        search: this.seekerValue,
        page: this.paramPage,
        page_size: this.paramPageSize
      }
      if (this.form.idSeller) queryParams.organization_id = this.form.idSeller
      return { params, queryParams }
    },
    changeRateId(rate) {
      const params = {
        carrier_id: this.form.carriers.id,
        service_id: this.services.rows[this.serviceIndex].id
      }
      this.rateId = rate
      const queryParams = {}
      if (this.form.idSeller) queryParams.organization_id = this.form.idSeller
      this.getServices('getRatesRatesQuoter', params, queryParams)
    },
    changeCountry(name, value) {
      this.seekerValue = ''
      this.changePlaces()
      this.$emit('getCarrier', {})
      this.buttonSend.disabled = true
      this.showTable = false
      if (value?.id) {
        this.countrySelected = value
        this.form = {
          ...this.form,
          places: null,
          categoryService: null,
          carriers: null,
          idSeller: null
        }
      } else {
        this.form = {}
      }
      this.cleanPlaces(['places', 'carriers', 'categoryService'])
    },
    changePlaces(name, value) {
      this.seekerValue = ''
      this.showTable = false
      this.form.categoryService = null
      this.form.carriers = null
      if (value) {
        const params = {
          country_id: this.form.country.id,
          place_id: value.id
        }
        const queryParams = {}
        if (this.form.idSeller) queryParams.organization_id = this.form.idSeller
        this.fields.forEach((el) => {
          if (el.name !== 'country' && el.name !== 'places') this.setSkeletonField(el.name, true)
        })
        this.getServices('getCarriersRatesQuoter', params, queryParams)
      } else {
        this.cleanPlaces(['places', 'carriers', 'categoryService'])
      }
    },
    changeIdSeller(name, value) {
      this.fields.forEach((el) => {
        if (el.name !== 'country' && el.name !== 'places' && el.name !== 'idSeller') this.setSkeletonField(el.name, true)
      })
      this.showTable = false
      this.form = {
        ...this.form,
        categoryService: null,
        carriers: null
      }
      const params = {
        country_id: this.form.country.id,
        place_id: this.form.places.id
      }
      const queryParams = {}
      if (value) {
        queryParams.organization_id = value
        this.$store.dispatch('fetchService', { name: 'getCarriersRatesQuoter', params, queryParams })
      } else {
        this.$store.dispatch('fetchService', { name: 'getCarriersRatesQuoter', params })
        this.cleanPlaces(['carriers', 'categoryService'])
      }
    },
    changeCarriers(name, value) {
      this.seekerValue = ''
      this.$emit('getCarrier', {})
      this.carrierSelected = value
      this.buttonSend.disabled = true
      this.showTable = false
      const params = {
        country_id: this.form.country.id,
        place_id: this.form.places.id,
        carrier_id: value?.id
      }
      const queryParams = {}
      if (this.form.idSeller) queryParams.organization_id = this.form.idSeller
      if (value?.id) {
        this.setSkeletonField('categoryService', true)
        this.$store.dispatch('fetchService', { name: 'getCategoryServiceRatesQuoter', params, queryParams })
      }
      this.form = {
        ...this.form,
        categoryService: null
      }
    },
    changeCategoryService(name, value) {
      this.seekerValue = ''
      this.rateId = null
      this.showTable = false
      const params = {
        country_id: this.form.country.id,
        place_id: this.form.places.id,
        carrier_id: this.form.carriers.id,
        service_type_id: value.id
      }
      const queryParams = {}
      if (this.form.idSeller) queryParams.organization_id = this.form.idSeller
      if (value?.code) {
        this.$store.dispatch('fetchService', { name: 'getServiceRatesQuoter', params, queryParams })
      } else {
        this.buttonSend.disabled = true
      }
    },
    changeSchema(data) {
      if (data.length) {
        this.arrayCurrentKgs = Object.keys(data[0].weight_intervals)
        this.arrayCurrentKgs = this.arrayCurrentKgs.sort(function (a, b) {
          return parseFloat(a) - parseFloat(b)
        })
        this.schema = [
          {
            label: 'Tiempo de tránsito',
            key: 'transit_days',
            class: ['text-center']
          }
        ]
        this.arrayCurrentKgs.forEach((el) => {
          this.schema.push({
            label: `Precio (${el}kg)`,
            key: `${el}kg`,
            class: ['text-right']
          })
        })
        if (this.form.country.id === 1) {
          // Confirma si el país es Chile
          this.schema.unshift({ label: 'Comuna', key: 'level0' })
          this.schema.unshift({ label: 'Región', key: 'level2' })
        } else if (this.form.country.id === 3) {
          // Confirma si el país es Colombia
          this.schema.unshift({ label: 'Municipio', key: 'level0' })
          this.schema.unshift({ label: 'Departamento', key: 'level2' })
          this.schema.unshift({ label: 'Código', key: 'dest_place_code' })
        } else if (this.form.country.id === 4) {
          // Confirma si el país es Perú
          this.schema.unshift({ label: 'Distrito', key: 'level1' })
          this.schema.unshift({ label: 'Provincia', key: 'level2' })
          this.schema.unshift({ label: 'Departamento', key: 'level3' })
          this.schema.unshift({ label: 'Ubigeo', key: 'level0' })
        } else if (this.form.country.id === 2) {
          // Confirma si el país es México
          this.schema.unshift({ label: 'Municipio', key: 'level1' })
          this.schema.unshift({ label: 'Estado', key: 'level2' })
          this.schema.unshift({ label: 'CP', key: 'level0' })
        }
      }
    },
    changeRatesCostsRadio(name) {
      this.ratesCostsRadio = name
      this.setRatesRatesQuoter()
    },
    setCountries() {
      const index = this.fields.findIndex((el) => el.name === 'country')
      let countryOptions = []
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        countryOptions = this.countries.rows
      } else if (['admin', 'superadmin', 'ecommerce', 'marketplace'].includes(this.$session.get('cas_user').role)) {
        countryOptions = this.countries.rows.filter((el) => 
        // se limita a estos 4 países ya que si en el futuro se agrega otro país debe ser verificado para ser liberado a los clientes
          el.code === 'MX' || el.code === 'CO' || el.code === 'CL' || el.code === 'PE'
        )
      } else {
        const index = this.fields.findIndex((el) => el.name === 'idSeller')
        if (index !== -1) this.fields.splice(index, 1)
      }
      this.fields[index].options = countryOptions
      this.fields.forEach((el) => this.setSkeletonField(el.name, false))
    },
    setPlaces(value) {
      this.$emit('getCarrier', {})
      this.buttonSend.disabled = true
      this.showTable = false
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {
        search: value
      }
      return this.baseService
        .callService('getPlacesRatesQuoter', queryParams, params)
        .then((resp) => {
          return resp.data.places.map((el) => ({
            ...el,
            text: el.full_name,
            valid: true
          }))
        })
        .catch((err) => {
          return err
        })
    },
    setCarriers() {
      // Eliminar objetos repetidos
      const rows = []
      // Paso 1: Iterar sobre el array original
      this.carriers.rows.forEach(el => {  
        // Paso 2: Verificar si el objeto ya existe en el nuevo array
        let exist = false
        rows.forEach(item => {
          if (JSON.stringify(item) === JSON.stringify(el)) {
            exist = true
          }
        })
        // Paso 3: Si el objeto no existe, agregarlo al nuevo array
        if (!exist) {
          rows.push(el)
        }
      })
      const inputs = ['idSeller', 'carriers', 'categoryService']
      inputs.forEach((el) => this.setSkeletonField(el, true))
      const index = this.fields.findIndex((el) => el.name === 'carriers')
      this.fields[index].options = rows
      setTimeout(() => inputs.forEach((el) => this.setSkeletonField(el, false)), 100)
    },
    setCategoryService() {
      const index = this.fields.findIndex((el) => el.name === 'categoryService')
      this.fields[index].options = this.categoryService.rows
      this.setSkeletonField('categoryService', false)
    },
    setServiceRatesQuoter() {
      if (this.services.rows.length) {
        this.serviceTypes = this.services.rows
        this.buttonSend.disabled = false
      }
    },
    setRatesRatesQuoter() {
      if (this.ratesCostsRadio === 'Tarifas Preferentes' || this.form.idSeller) {
        this.filterRates = this.rates.rows.filter((el) => (el.rate_customization_type === 'differential'))
      } else {
        this.filterRates = this.rates.rows.filter((el) => (el.rate_customization_type === 'general'))
      }
      this.rateServiceId = this.filterRates.find((el) => el.type === 'current') || this.filterRates[0]
      this.rateId = this.filterRates?.some((el) => el.id === this.rateId) ? this.rateId : this.rateServiceId.id
      if (this.ratesCostsRadioOptions.includes(this.ratesCostsRadio)) {
        this.getCoverages()
      } else {
        this.ratesCostsRadio = 'Tarifas'
      }
    },
    setSkeletonField(name, bool) {
      const index = this.fields.findIndex((el) => el.name === name)
      if (index !== -1) {
        this.fields[index].useSlot = bool
        this.fields[index].useSkeleton = bool
        this.fields[index].useLabel = bool
      }
    },
    setPricesRatesQuoter() {
      this.rows = []
      let currentId = 0
      if (!this.isExpress) this.changeSchema(this.prices.rows)
      this.paramPage = this.prices.meta?.current_page || 1
      this.pagination.total = this.prices.meta?.total_rows || 0
      if (this.form.country.code === 'CO') {
        const indexFreight = this.schema.findIndex((el) => el.key === 'freight_minimum_cost')
        const indexPercentage = this.schema.findIndex((el) => el.key === 'freight_percentage_cost')
        const freight_minimum_cost = {
          label: 'Flete de manejo minimo',
          key: 'freight_minimum_cost',
          class: ['text-right']
        }
        const freight_percentage_cost = {
          label: '% flete de manejo',
          key: 'freight_percentage_cost',
          class: ['text-center']
        }
        if (indexFreight === -1) this.schema.splice(3, 0, freight_minimum_cost)
        if (indexPercentage === -1) this.schema.splice(4, 0, freight_percentage_cost)
      } else {
        const indexFreight = this.schema.findIndex((el) => el.key === 'freight_minimum_cost')
        if (indexFreight !== -1) this.schema.splice([indexFreight], 1)
        const indexPercentage = this.schema.findIndex((el) => el.key === 'freight_percentage_cost')
        if (indexPercentage !== -1) this.schema.splice([indexPercentage], 1)
      }
      if (this.isExpress) {
        [this.prices.rows.weight_intervals].map((el) => {
          const keysRows = Object.keys(el)
          keysRows.map((key) => {
            this.prices.rows.weight_intervals[key] = this.prices.rows.weight_intervals[key].fp
          })
        })
        this.rows = [this.prices.rows]
        if (this.rows.length) this.rows[0].weight_intervals.price = 'Precio'
      } else {
        this.prices.rows.map(({ weight_intervals, freight_percentage_cost, dest_place_name, ...rest }) => {
          const percentage = `${freight_percentage_cost} %`
          const levelsPlace = dest_place_name.split(', ')
          let level0MX = ''
          if (this.form.country.id === 2) level0MX = levelsPlace[0].replace('CP ', '')
          const row = {
            id: currentId,
            ...rest,
            freight_percentage_cost: percentage,
            level0: level0MX || levelsPlace[0],
            level1: levelsPlace[1],
            level2: levelsPlace[2],
            level3: levelsPlace[3]
          }
          this.arrayCurrentKgs.forEach((el) => {
            row[`${el}kg`] = weight_intervals[el]?.fp
          })
          this.rows.push(row)
          currentId++
        })
      }
      this.showTable = true
    },
    cleanPlaces(fieldsArray) {
      fieldsArray.forEach((el) => this.setSkeletonField(el, true))
      setTimeout(() => {
        fieldsArray.forEach((el) => this.setSkeletonField(el, false))
      }, 200)
    },
    buttonFind() {
      this.$emit('getCarrier', this.form.carriers)
      const params = {
        carrier_id: this.form.carriers?.id,
        service_id: this.services.rows[this.serviceIndex]?.id
      }
      const queryParams = {}
      if (this.form.idSeller) queryParams.organization_id = this.form.idSeller
      this.getServices('getRatesRatesQuoter', params, queryParams)
    },
    searchSpecifically() {
      this.pagination.page === 1 ? this.getCoverages() : (this.pagination.page = 1)
    },
    exportExcel() {
      if (this.filterRates.length) {
        const params = {
          carrier_id: this.form.carriers.id,
          service_id: this.services.rows[this.serviceIndex].id,
          rate_id: this.rateId,
          place_id: this.form.places.id,
          pricesOrCosts: this.pricesOrCosts,
          carrier_name: this.form.carriers.name,
          places_name: this.form.places.name,
          service_name: this.services.rows[this.serviceIndex].name
        }
        const queryParams = {}
        if (this.form.idSeller) queryParams.organization_id = this.form.idSeller
        this.$store.dispatch('getFiles', { name: 'getFileRatesQuoter', params, queryParams })
      }
    },
    cleanFilter() {
      this.rateId = null
      this.form = {}
      this.showTable = false
      this.buttonSend.disabled = true
    },
    openModal() {
      this.$bvModal.show('ModaltariffsHistory')
    },
    getServices(name, params, queryParams) {
      this.$store.dispatch('fetchService', { name, params, queryParams })
    },
    cleanSearch() {
      this.seekerValue = ''
      this.getCoverages()
    }
  }
}
</script>
<style>
.is-disabled {
  background-color: #f8f8f8 !important;
  opacity: 0.5;
}
.form-group-tariffs > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
