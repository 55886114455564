<template>
  <div>
    <b-tabs v-model="dataList.tabIndex" :align="'center'" class="tab-primary pt-4" justified @input="changeServiceType">
      <b-tab v-for="(services, index) in dataList.servicesTypes" :key="index">
        <template #title>
          <div class="text-center">
            <span v-html="services.name"></span>
          </div>
        </template>
      </b-tab>
    </b-tabs>
    <div v-if="!dataList.form.lengthValue || !dataList.form.heightValue || !dataList.form.widthValue" justified>
      <b-alert variant="warning" show>
        <div class="alert-body d-flex align-items-center">
          <feather-icon class="mr-25" icon="AlertTriangleIcon" />
          <ul class="mb-0">
            <li v-if="dataList.servicesTypes[dataList.tabIndex] === 'express' && dataList.form.countryOfDestination.code.toLowerCase() !== 'us'">
              El <span class="font-weight-bolder">servicio Express</span> establece parámetros de cobertura por KM (el destino indicado no será considerado)
            </li>
            <li v-if="(!dataList.form.lengthValue || !dataList.form.heightValue || !dataList.form.widthValue) && dataList.form.countryOfDestination.code.toLowerCase() !== 'us'">
              Recomendamos <span class="font-weight-bolder">indicar las dimensiones del envío,</span> ya que pueden ocasionar cambios en las tarifas
              efectivas
            </li>
            <li>{{`TARIFAS NO INCLUYEN ${dataList.form.countryOfOrigin.code.toLowerCase() === 'pe' ? 'IGV' : dataList.form.countryOfDestination.code.toLowerCase() === 'us' ? 'IMPUESTOS' : 'IVA'}`}}</li>
          </ul>
        </div>
      </b-alert>
    </div>
    <div :class="'table-quoter tableWitchIcon table-scroll'">
      <b-table 
        responsive="sm"
        :key="keyTableRender" 
        :items="sortedItems"
        :fields="schema"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        no-local-sorting
        show-empty
        borderless= "borderless"
        stickyColumn="true"
      >
        <template #empty="">
          <span class="text-center w-full push-left">No se encontraron registros para mostrar</span>
        </template>
        <template #cell(carrier)="scope">
          <div class="container-img-tablequoter"><img class="logo-table-quoter" :alt="scope.item.carrier" :src="`https://storage.googleapis.com/enviame-misc/carriers/logos/color/${scope.item.carrier.toLowerCase()}.png`"/></div>
        </template>
        <template #cell(coverage)="scope">
          <span>{{ scope.item.coverage }}</span>
        </template>
        <template #head()="data">
          <span :class="{'cursor-pointer' : true }">
            <span>{{ data.field.label }}</span>
            <feather-icon class="" v-if="data.field.icon" :icon="data.field.icon" v-b-tooltip.top :title="data.field.text"/>
          </span>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>

export default {
  props: ['dataList', 'changeTab'],
  name: 'OneWeightList',
  data () {
    return {
      keyTableRender: 0,
      schema: [],
      rows: [],
      sortBy: 'carrier',
      sortDesc: false
    }
  },
  computed: {
    sortedItems() {
      return this.sortRows(this.sortBy, this.sortDesc, this.rows)
    }
  },
  mounted () {
    this.loadedPrices()
  },
  methods: {
    setSchema () {
      this.schema = [
        {label: 'Courier', sortable: true, key: 'carrier', useSlot: true, class: ['text-center', 'first-table', 'quoterTableHead']},
        {label: 'Servicio', sortable: true, key: 'service', class: ['text-center', 'first-table', 'quoterTableHead']},
        {label: 'Tiempo de entrega', sortable: true, key: 'days', width: '5%', class: ['text-center', 'separation-border-1', 'first-table', 'quoterTableHead']},
        {label: '', useSlot: true, key: 'separation', class: ['separation-thead']}
      ]
      if (this.dataList.form.cashOnDelivery) {
        this.schema.push(
          {label: 'Tarifa flete ', sortable: true, text: 'Valor del Envío', key: 'coverage', icon: 'InfoIcon', class: ['text-center', 'separation-border-2', 'second-table', 'quoterTableHead']},
          {label: '% recaudo ', sortable: true, key: 'public_percentage_cash', class: ['text-center', 'second-table', 'quoterTableHead'], icon: 'InfoIcon', text:'Porcentaje de Comisión de Recaudo que cobrará el Courier'},
          {label: 'Tarifa mínima de recaudo ', sortable: true, key: 'public_min_payment_cash', class: ['text-center', 'second-table', 'quoterTableHead', 'width-public_min-payment-cash'], icon: 'InfoIcon', text:'Valor mínimo a pagar por el servicio de Recaudo'},
          {label: 'Tarifa recaudo ', sortable: true, key: 'cod_value_cash_formatted', class: ['text-center', 'second-table', 'quoterTableHead'], icon: 'InfoIcon', text:'Valor del Recaudo en función de los valores ingresados'},
          {label: 'Monto total ', sortable: true, key: 'price_with_cod', class: ['text-center', 'second-table', 'quoterTableHead'], icon: 'InfoIcon', text:'La suma total de la Tarifa de Flete y la Tarifa de Recaudo'},
          {label: 'Kg-Courier ', sortable: true, key: 'kg_courier', class: ['text-center', 'second-table', 'quoterTableHead'], icon: 'InfoIcon', text:'Equivalencia Peso-Volumen del envío según las políticas del Courier en función de las dimensiones y/o peso ingresados'}
        )
      } else {
        this.schema.push(
          {label: 'Tarifa ', sortable: true, text: 'Precio considerando Peso-Volumen', key: 'coverage', icon: 'InfoIcon', class: ['text-center', 'separation-border-2', 'second-table', 'quoterTableHead']},
          {label: 'Kg-Courier ', sortable: true, key: 'kg_courier', class: ['text-center', 'second-table', 'quoterTableHead'], icon: 'InfoIcon', text:'Equivalencia Peso-Volumen del envío según las políticas del Courier en función de las dimensiones y/o peso ingresados'}
        )
      }
      this.keyTableRender++
    },
    loadedPrices () {
      this.rows = []
      let currentId = 0
      this.dataList.data.map((rowKg) => {
        rowKg.data.map((rowService) => {
          rowService.services.map((detailService) => {
            const currentKg = rowService.weight
            const indexRowServiceCarrierNoKg = this.rows.findIndex(row => {
              return row.service_code === detailService.code && row.carrier === rowService.carrier && !row[`${currentKg}kg`] && row[`${currentKg}kg`] !== 0
            })
            if (indexRowServiceCarrierNoKg !== -1) {
              this.rows[indexRowServiceCarrierNoKg][`${currentKg}kg`] = detailService.price_formatted
            } else {
              const row = {
                id: currentId,
                carrier: rowService.carrier, 
                service_code: detailService.code, 
                service: detailService.name,
                days: `${detailService.transit_days} ${parseInt(detailService.transit_days) > 1 ? 'días' : 'día'}`,
                group_key: detailService.group_by_carrier_service_type ? `${detailService.service_types[0]}_${rowService.carrier}`.toLowerCase() : ''
              }
              row[`${currentKg}kg`] = detailService.price_formatted || '--'
              row.coverage = detailService.price_formatted || '--'
              row.kg_courier = `${currentKg} kg` || '--'
              row.public_percentage_cash = `${rowService.cod_information?.public_percentage_cash} %` || '--'
              row.public_min_payment_cash = rowService.cod_information?.public_min_payment_cash_formatted || '--'
              row.cod_value_cash_formatted = rowService.cod_information?.cod_value_cash_formatted || '--'
              row.price_with_cod = detailService.price_formatted_with_cod || '--'
              this.rows.push(row)
              currentId++
            }
          })
        })
      })
      this.setSchema()
    },
    sortRows(sortBy, sortDesc, rows) {
      if (sortBy.includes('kg') || sortBy.includes('km') || sortBy.includes('KM')) {
        return rows.slice().sort((a, b) => {
          const aValue = typeof a[sortBy] === 'string' ? parseInt(a[sortBy]?.replaceAll(/[^0-9]/g, '')) : a[sortBy]
          const bValue = typeof a[sortBy] === 'string' ? parseInt(b[sortBy]?.replaceAll(/[^0-9]/g, '')) : b[sortBy]
          return sortDesc ? aValue - bValue : bValue - aValue
        })
      } else {
        return rows.slice().sort((a, b) => {
          return sortDesc ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy])
        })
      }
    },
    changeServiceType(tab) {
      this.$emit('changeTab', tab)
    }
  }
}
</script>
<style>
.quoterTableHead {
  background-position: right 10% center !important;
}
.first-table {
  min-width: 130px;
  width: 130px;
}
.second-table {
  min-width: 200px;
  width: 200px;
}
.table-scroll {
  width: 100%;
  overflow-x: auto;
}
.width-public_min-payment-cash {
  min-width: 316px !important;
}
</style>